const keyMap = {
  background: 'backgroundImage',
  textLine1: 'fonts',
  textLine2: 'fonts',
  watermark: 'watermarks',
};

export const fetchConfigData = (data, key) => {
  const { seenit: { organisation: { quickEditsConfig = {} } = {} } = {} } =
    data || {};

  const { [keyMap[key]]: responseTab = [] } = quickEditsConfig;
  const defaultOrg = {
    value: '-1',
    text: 'Select from the list or leave this to remove media',
  };

  return [
    defaultOrg,
    ...responseTab.map(({ quickEditMediaId, title }) => ({
      value: quickEditMediaId,
      text: title || quickEditMediaId,
    })),
  ];
};
