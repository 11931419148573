import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { Button, Typography, Icon } from '@team-seenit/atoms';
import { FormControl, StaticPage } from '@team-seenit/molecules';
import { Error } from '../../../molecules';

import * as Styled from './Brief.styled';

export function FeatureBriefPresenter({
  fetchError,
  registeredFeatureId,
  dataEl,
  errors,
  title,
  buttonTitle,
  handleFormChanges,
  getValue,
  isformValid,
  submitted,
  handleFormSubmission,
  handleDeleteClick,
}): JSX.Element {
  const { push } = useHistory();

  if (fetchError) {
    return (
      <StaticPage
        body={`Feature with id ${registeredFeatureId}`}
        buttonProps={{
          children: 'Back to feature list',
          dataEl: 'DataEl',
          onClick: () => push('/tiers/features'),
        }}
        dataEl="LibraryLoading"
        emoticonProps={{
          dataEl: 'LibraryLoadingEmoji',
          emoji: null,
          emojiComponent: (
            <Icon
              color="red"
              dataEl="LibraryLoadingIcon"
              name="error"
              size="massive"
            />
          ),
        }}
        title="Error fetching data for"
      />
    );
  }

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Error rawErrors={errors} />

      <Typography dataEl="CreateTier" variant="body">
        {title}
      </Typography>
      <FormControl
        dataEl="CreateFeatureName"
        inputProps={{
          placeholder: 'Enter feature name...',
          dataEl: 'CreateTierNameTextInput',
          id: 'name',
          onChange: handleFormChanges,
          value: getValue('name').value,
          valid: getValue('name').valid,
          readOnly: !!registeredFeatureId,
        }}
        type="text"
        validateOn="change"
        validators={[
          {
            errorMessage: 'Sorry, but we’re going to need a feature name.',
            validator: 'required',
          },
          {
            errorMessage:
              'Sorry, but we’re going to need a little more than that.',
            validator: 'minLength',
            min: 2,
          },
        ]}
      />
      <FormControl
        dataEl="CreateFeatureDescription"
        inputProps={{
          placeholder: 'Enter feature description...',
          dataEl: 'CreateTierDescriptionTextInput',
          id: 'description',
          onChange: handleFormChanges,
          value: getValue('description').value,
          valid: getValue('description').valid,
        }}
        type="text"
        validateOn="change"
        validators={[
          {
            errorMessage: 'Sorry, but we’re going to need a tier description.',
            validator: 'required',
          },
          {
            errorMessage:
              'Sorry, but we’re going to need a little more than that.',
            validator: 'minLength',
            min: 2,
          },
        ]}
      />

      <FormControl
        dataEl="CreateFeatureValueIndicator"
        hint="This is the type of value users can set on this feature."
        hintStyle="inline"
        inputProps={{
          dataEl: 'CreateTierDescriptionTextInput',
          id: 'valueIndicator',
          options: [
            {
              text: 'Select an option',
              value: '',
            },
            {
              text: 'Boolean value',
              value: 'boolean',
            },
            {
              text: 'Text value',
              value: 'text',
            },
            {
              text: 'Number value',
              value: 'number',
            },
          ],
          onChange: handleFormChanges,
          value: getValue('valueIndicator').value,
          valid: getValue('valueIndicator').valid,
        }}
        question=" "
        type="select"
      />

      <Styled.Buttons>
        <Button
          disabled={!isformValid()}
          loading={submitted}
          onClick={handleFormSubmission}
          rounded
        >
          {buttonTitle}
        </Button>

        {registeredFeatureId && (
          <Button
            backgroundColor="red"
            loading={submitted}
            onClick={handleDeleteClick}
            rounded
          >
            Delete feature
          </Button>
        )}

        <Button
          backgroundColor="yellow"
          loading={submitted}
          onClick={() => push(`/tiers/features`)}
          rounded
        >
          Return to Feature list
        </Button>
      </Styled.Buttons>
    </Styled.Wrapper>
  );
}

FeatureBriefPresenter.propTypes = {
  /** DataEl for the component. */
  dataEl: PropTypes.string,
  /** DataEl for the component. */
  registeredFeatureId: PropTypes.string,
  /** Component title */
  title: PropTypes.string.isRequired,
  /** Component button title */
  buttonTitle: PropTypes.string.isRequired,
  /** Function to get field value. */
  getValue: PropTypes.func.isRequired,
  /** Function to handle form changes. */
  handleFormChanges: PropTypes.func.isRequired,
  /** Function to handle form submission. */
  handleFormSubmission: PropTypes.func.isRequired,
  /** Function to handle delete. */
  handleDeleteClick: PropTypes.func.isRequired,
  /** GQL fetch errors */
  fetchError: PropTypes.objectOf(PropTypes.any),
  /** GQL errors */
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  /** Boolean field of the state of the form */
  submitted: PropTypes.bool.isRequired,
  /** Boolean field to say the form is valid */
  isformValid: PropTypes.bool.isRequired,
};

FeatureBriefPresenter.defaultProps = {
  dataEl: 'FeatureBriefPresenter',
  registeredFeatureId: undefined,
  fetchError: undefined,
};
