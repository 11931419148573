import { gql } from '@apollo/client';

export const CREATE_QUICK_EDIT_MEDIUM_MUTATION = gql`
  mutation CreateQuickEditMediumMutation($data: CreateQuickEditMediumInput!) {
    seenit {
      createQuickEditMedium(data: $data) {
        quickEditMediaId
      }
    }
  }
`;
