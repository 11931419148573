import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  marginBottom: '10px',
  gap: '20px',
}));

export const TabSideWrapper = styled.div(() => ({
  flex: 1,
  marginBottom: '10px',
}));

export const SwitchWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
}));

export const InputField = styled.div(() => ({}));

export const InputWrapper = styled.div(() => ({}));
