import React from 'react';

import { StaticPage } from '@team-seenit/molecules';
import { LOGOS } from '@team-seenit/constants';

export const NotAuthorisedPage = () => {
  const emoticon = '😸';
  const subtitle = 'Ask the Devs nicely, they may allow you access.';
  const title = 'Only Seenit admin users are allowed.';
  const dataEl = 'UNATHOURISED_USER';

  return (
    <StaticPage
      body={subtitle}
      dataEl={dataEl}
      emoticonProps={{
        dataEl: `${dataEl}Emoticon`,
        emoji: emoticon,
      }}
      logoProps={{
        alt: `${dataEl}Logo`,
        dataEl: `${dataEl}Logo`,
        src: LOGOS.SEENIT_LOGO_ACCENT,
      }}
      title={title}
    />
  );
};
