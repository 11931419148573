import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Pill, Image, HR, Typography, Icon } from '@team-seenit/atoms';
import { StatsCard, VideoPlayer } from '@team-seenit/molecules';
import { v4 as uuidv4 } from 'uuid';
import { MediaTitle } from './MediaTitle';
import * as Styled from '../MediaUploadOrganism.styled';
import { MediaUploadCurrentStateProps } from './MediaUploadCurrentState.types';
import { mutations, queries } from '../MediaUploadOrganism.gql';
import { OrgTypeEnum } from '../../EditorConfigEnums';

export const MediaUploadCurrentState = ({
  dataEl = 'MediaCurrentState',
  orgId,
  currentTab,
  refetchMedia,
  setRefetchMedia,
  orgType,
}: MediaUploadCurrentStateProps): JSX.Element => {
  const [
    getMedia,
    { data: getMediaData, error, refetch, loading },
  ] = useLazyQuery(queries.getMedia);

  const [getOrg, { data: getOrgData }] = useLazyQuery(queries.getOrganisation);

  useEffect(() => {
    if (refetchMedia) {
      refetch();
      setRefetchMedia(false);
    }
  }, [refetchMedia]);

  useEffect(() => {
    if (orgId) {
      getMedia({ variables: { filter: { orgId, mediaType: currentTab } } });
      if (orgType === OrgTypeEnum.AllOrganisations)
        getOrg({ variables: { orgId } });
    }
  }, [orgId]);

  const [deleteEditorMedium, { data: deletedMedium }] = useMutation(
    mutations.deleteMedia
  );

  useEffect(() => {
    getMedia({ variables: { filter: { orgId, mediaType: currentTab } } });
  }, [orgId, deletedMedium, currentTab]);

  const onDeleteClick = editorMediaId => {
    deleteEditorMedium({ variables: { editorMediaId } });
    refetch();
  };

  const name = getOrgData?.seenit?.organisation?.name;
  const nickname = getOrgData?.seenit?.organisation?.nickname;
  const avatar = getOrgData?.seenit?.organisation?.avatar;
  const tier = getOrgData?.seenit?.organisation?.tier;

  if (error) return <Typography variant="headline">GQL Error</Typography>;

  if (loading)
    return (
      <Icon
        animation="spin"
        color="accent"
        dataEl="SearchLoadingSpinner"
        name="loading"
        size="large"
      />
    );

  return (
    <Styled.Wrapper dataEl={dataEl}>
      {orgType === OrgTypeEnum.AllOrganisations && (
        <Styled.TierWrapper>
          <Pill color="accent" readOnly text={`Current Tier: ${tier?.name}`} />
        </Styled.TierWrapper>
      )}

      <StatsCard
        color="blue"
        icon="brand_editor"
        stats={getMediaData?.seenit?.editorMedia?.length || 0}
        title="Total"
      />
      {getMediaData &&
        getMediaData?.seenit?.editorMedia?.map(item => {
          return (
            <Styled.Empty key={item.editorMediaId}>
              <Typography variant="subheader">{item.editorMediaId}</Typography>
              <MediaTitle mediumId={item.editorMediaId} title={item.title} />
              <Styled.MediaWrapper
                key={item.editorMediaId}
                id={item.editorMediaId}
              >
                <Button
                  icon="cut"
                  onClick={() => onDeleteClick(item.editorMediaId)}
                  variant="outlineDanger"
                >
                  Delete
                </Button>
                {item.mediaFormat === 'video' && currentTab !== 'fonts' && (
                  <Styled.VideoPlayer isAudio={item.isAudio}>
                    {!item.isAudio && (
                      <VideoPlayer
                        borderRadius="8px"
                        captions={{}}
                        childrenVisible
                        dataEl={dataEl}
                        description={item.title}
                        onChangeSubtitles={() => {}}
                        orgAvatar={avatar}
                        orgName={`${name} ${nickname || ''}`}
                        publicId={item.uploadUrl}
                        subtitles={[{}]}
                        subtitlesVisible={false}
                        thumbnailUrl={item.thumbnailUrl}
                        title={item.title}
                        videoControlsVisible
                      />
                    )}
                    {item.isAudio && (
                      <audio controls>
                        <track kind="captions" />
                        <source src={item.uploadUrl} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                  </Styled.VideoPlayer>
                )}
                {item.mediaFormat === 'image' && (
                  <Styled.ImageDisplay>
                    <Image
                      borderRadius="8px"
                      dataEl="Image"
                      height="136px"
                      objectFit="contain"
                      publicId={item.publicId}
                      src={item.thumbnailUrl}
                      width="208px"
                    />
                  </Styled.ImageDisplay>
                )}
                {currentTab === 'fonts' && (
                  <Styled.CustomFont
                    fontFamily={`Font_${uuidv4()}`}
                    fontUrl={item.uploadUrl}
                  >
                    The quick brown fox jumps over the lazy dog
                  </Styled.CustomFont>
                )}
              </Styled.MediaWrapper>
              <HR color="accent" />
            </Styled.Empty>
          );
        })}
    </Styled.Wrapper>
  );
};
