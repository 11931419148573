import { defaultFormProps } from './defaultFormProps';

const floatingKeys = [
  'positionX',
  'positionY',
  'positionYWhenNoLine2',
  'height',
  'width',
  'fadeIn',
  'duration',
];

const getValue = (currentKey, data) => {
  if (!data[currentKey] || !data[currentKey].value) return;

  return floatingKeys.includes(currentKey)
    ? parseFloat(data[currentKey].value)
    : data[currentKey].value;
};

export const formDataBySection = (section, data) => {
  const { [section]: sectionData = {} } = defaultFormProps;
  const sectionKeys = Object.keys(sectionData);

  const resp = sectionKeys.reduce((prev, curr) => {
    return {
      ...prev,

      [curr]: getValue(curr, data[section]),
    };
  }, {});

  return {
    [section]: resp,
  };
};
