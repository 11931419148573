import { gql } from '@apollo/client';

export const queries = {
  getMedia: gql`
    query MediaCurrentStateGetEditorMediaByType(
      $filter: EditorMediaFilterInput
    ) {
      seenit {
        editorMedia(filter: $filter) {
          editorMediaId
          title
          mediaFormat
          isAudio
          uploadUrl
          thumbnailUrl
          publicId
        }
      }
    }
  `,

  getOrganisation: gql`
    query MediaCurrentStateGetOrganisation($orgId: ID!) {
      seenit {
        organisation(orgId: $orgId) {
          orgId
          name
          nickname
          avatar
          tier {
            tierId
            name
          }
        }
      }
    }
  `,
};

export const mutations = {
  createEditorMedium: gql`
    mutation adminCreateEditorMedium($data: adminCreateEditorMediumInput!) {
      seenit {
        adminCreateEditorMedium(data: $data) {
          editorMediaId
          mediaType
          publicId
        }
      }
    }
  `,
  deleteMedia: gql`
    mutation DeleteEditorMedium($editorMediaId: ID!) {
      seenit {
        deleteEditorMedium(editorMediaId: $editorMediaId) {
          success
        }
      }
    }
  `,
  updateMedia: gql`
    mutation UpdateEditorMedium(
      $editorMediaId: ID!
      $data: UpdateEditorMediumInput!
    ) {
      seenit {
        updateEditorMedium(editorMediaId: $editorMediaId, data: $data) {
          editorMediaId
        }
      }
    }
  `,
};
