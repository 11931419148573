import { gql } from '@apollo/client';

export const mutations = {
  createFloatingOrg: gql`
    mutation CreateFloatingOrg($data: CreateFloatingOrgInput!) {
      seenit {
        createFloatingOrg(data: $data) {
          orgId
        }
      }
    }
  `,
};
