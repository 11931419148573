import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@team-seenit/molecules';

import { useQuery } from '@apollo/client';

import {
  GET_ORGANISATIONS_QUERY,
  destructureDataForOrganisations,
} from './Organisations.gql';
import * as Styled from './Organisations.styled';

import { OrganisationBrief } from './brief/OrganisationBrief';

export function Organisations({ dataEl }): JSX.Element {
  const [currentOrgId, setCurrentOrgId] = useState<undefined>();

  const { data, error } = useQuery(GET_ORGANISATIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { organisationSelector } = destructureDataForOrganisations(
    data,
    currentOrgId
  );

  if (error) return <h1>GQL Error2</h1>;

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <FormControl
        dataEl="Organisations"
        inputProps={{
          dataEl: 'OrganisationsInput',
          id: 'organisations',
          options: organisationSelector,
          onChange: value => setCurrentOrgId(value),
          value: () => console.log(''),
          valid: true,
        }}
        type="select"
        validateOn="change"
      />

      {currentOrgId && currentOrgId !== '-1' && (
        <OrganisationBrief orgId={currentOrgId} />
      )}
    </Styled.Wrapper>
  );
}

Organisations.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
};

Organisations.defaultProps = {
  dataEl: 'Organisations',
};
