/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { queries } from './LowerThirds.gql';
import { LowerThirdsForm } from './Form';
import { LowerThirdsCurrentState } from './CurrentState';
import { defaultFormProps } from './helpers';
import { LowerThirdsProps } from './LowerThirds.types';
import * as Styled from './LowerThirds.styled';
import { validationSchema } from './helpers/validationSchema';

export const LowerThirds = ({ orgId, dataEl }: LowerThirdsProps) => {
  const [selectedLowerThird, setSelectedLowerThird] = useState();

  const methods = useForm({
    defaultValues: { ...defaultFormProps },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const [
    fetchOrgLowerThirds,
    { data: getOrgLowerThirdsData, refetch },
  ] = useLazyQuery(queries.getEditorLowerThirds);

  useEffect(() => {
    if (orgId) {
      fetchOrgLowerThirds({ variables: { filter: { orgId } } });
    }
  }, [orgId]);

  return (
    <FormProvider {...methods}>
      <Styled.TabSideWrapper dataEl={dataEl}>
        <LowerThirdsForm
          selectedLowerThird={selectedLowerThird}
          setSelectedLowerThird={setSelectedLowerThird}
          dataEl={dataEl}
          orgId={orgId}
          refetchLowerThirds={refetch}
        />
      </Styled.TabSideWrapper>

      <Styled.TabSideWrapper>
        <LowerThirdsCurrentState
          lowerThirds={getOrgLowerThirdsData?.seenit?.lowerThirds}
          selectedLowerThird={selectedLowerThird}
          setSelectedLowerThird={setSelectedLowerThird}
          refetchLowerThirds={refetch}
        />
      </Styled.TabSideWrapper>
    </FormProvider>
  );
};
