import { gql } from '@apollo/client';

// export type TierData = {
//   tierId?: string;
//   name?: string;
//   description?: string;
// };

export const GET_TIERS_QUERY = gql`
  query KayAdminGetAllTiers {
    seenit {
      admin {
        tiers {
          docType
          tierId
          name
          description
        }
      }
    }
  }
`;

export const destructureDataForTiers = ({
  seenit: { admin: { tiers = [] } = {} } = {},
} = {}) => tiers;
