import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Switch, Typography } from '@team-seenit/atoms';
import { StaticPage } from '@team-seenit/molecules';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import {
  GET_TOGGLE_QUERY,
  UPDATE_TOGGLE_MUTATION,
  DELETE_TOGGLE_MUTATION,
  destructureDataForToggles,
} from './Toggles.gql';
import * as Styled from './Toggles.styled';

export const Toggles = ({ dataEl }): JSX.Element => {
  const { data } = useQuery(GET_TOGGLE_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateToggle, { data: updateToggleData }] = useMutation(
    UPDATE_TOGGLE_MUTATION
  );
  const [deleteToggleFunction, { data: deleteToggleData }] = useMutation(
    DELETE_TOGGLE_MUTATION
  );
  const [fetchToggle, { refetch }] = useLazyQuery(GET_TOGGLE_QUERY);

  const handleUpdateToggle = ({ toggleId, toggleDetails }) => {
    updateToggle({ variables: { toggleId, toggleDetails } });
  };

  const handleDeleteToggle = toggleId => {
    deleteToggleFunction({ variables: { toggleId } });
  };

  useEffect(() => {
    fetchToggle();
  }, []);

  useEffect(() => {
    if (updateToggleData || deleteToggleData) {
      refetch();
    }
  }, [updateToggleData, deleteToggleData]);

  const toggles = destructureDataForToggles(data);

  const emoticon = 'ℹ️';
  const subtitle = 'You can create some by pressing the green button above.';
  const title = 'No toggles at the moment.';

  return (
    <Styled.Wrapper dataEl={dataEl}>
      {!toggles.length && (
        <StaticPage
          body={subtitle}
          dataEl={dataEl}
          emoticonProps={{
            dataEl: `${dataEl}Emoticon`,
            emoji: emoticon,
          }}
          title={title}
        />
      )}
      {!!toggles.length && (
        <Styled.TableWrapper key="toggles-list">
          <Typography variant="header">List of current toggles</Typography>

          <table>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {toggles &&
              toggles.map((toggle, index) => (
                <tr key={toggle.toggleId}>
                  <td>{index + 1}</td>
                  <td>{toggle.name}</td>
                  <td>
                    <Switch
                      checked={toggle.status}
                      dataEl="ToggleUpdate"
                      onChange={() =>
                        handleUpdateToggle({
                          toggleId: toggle.toggleId,
                          toggleDetails: { status: !toggle.status },
                        })
                      }
                      readOnly={false}
                    />
                  </td>
                  <td>
                    <Button
                      backgroundColor="red"
                      dataEl="ToggleDelete"
                      onClick={() => handleDeleteToggle(toggle.toggleId)}
                      rounded
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </table>
        </Styled.TableWrapper>
      )}
    </Styled.Wrapper>
  );
};

Toggles.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
};

Toggles.defaultProps = {
  dataEl: 'Toggles',
};
