import React from 'react';

import styled from 'styled-components';
import { ErrorBoundary } from '@sentry/react';
import { useAnalytics } from '@team-seenit/contexts';
import { Typography } from '@team-seenit/atoms';
import { StudioTopBar, StaticPages } from '@team-seenit/organisms';
import { StudioTemplate } from '@team-seenit/templates';
import { CONTENTFUL } from '@team-seenit/constants';
import { useHistory } from 'react-router';
import {
  Tiers,
  TierBrief,
  Features,
  FeatureBrief,
  Organisations,
  TierOrganisations,
} from '../organisms';
import { MenuDesktopHelper } from './components/MenuDesktopHelper';

const {
  KEYS: { PROJECTS_MAIN_ERROR_GRAPHQL },
} = CONTENTFUL;

const Main = styled.main(
  ({ theme: { breakpoints: { min: { desktop = undefined } = {} } = {} } }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 16px',
    width: '100%',
    'z-index': 0,
    [`@media (min-width: ${desktop})`]: {
      padding: '32px',
    },
  })
);

export const TiersPage = () => {
  const { page } = useAnalytics();
  const {
    location: { pathname },
  } = useHistory();

  const featureSetup = pathname.match(/tiers\/features\/.*\/setup/);
  const tierSetup = pathname.match(/tiers\/(?!features).*\/setup/);
  const tierOrganisationsSetup = pathname.match(/tiers\/.*\/organisations/);

  page('ADMIN', 'Admin Tiers');

  return (
    <StudioTemplate
      dataEl="TiersPage"
      mainComponent={
        <ErrorBoundary
          fallback={({ eventId }) => (
            <StaticPages.ErrorGraphQL
              contentLocationKey={PROJECTS_MAIN_ERROR_GRAPHQL}
              eventId={eventId}
            />
          )}
        >
          <Main>
            <Typography variant="header">
              Tooltip appears broken in Pluto, hence menu items are just icons
            </Typography>
            <Typography variant="header">Logout using the avatars</Typography>
            {pathname === '/tiers/features' && <Features />}
            {pathname === '/tiers/features/create' && <FeatureBrief />}
            {!!featureSetup && <FeatureBrief />}

            {pathname === '/tiers' && <Tiers />}
            {pathname === '/tiers/create' && <TierBrief />}
            {tierSetup && <TierBrief />}
            {tierOrganisationsSetup && <TierOrganisations />}

            {pathname === '/tiers/organisations' && <Organisations />}
          </Main>
        </ErrorBoundary>
      }
      menuDesktopComponent={<MenuDesktopHelper />}
      topBarComponent={<StudioTopBar />}
    />
  );
};
