import { gql } from '@apollo/client';
import { RegisteredFeatureDetails } from '../fragments';

export const GET_REGISTERED_FEATURES_QUERY = gql`
  query AdminGetAllFeatures {
    seenit {
      admin {
        registeredFeatures {
          ...RegisteredFeatureDetails
        }
      }
    }
  }

  ${RegisteredFeatureDetails}
`;

export const destructureDataForFeatures = ({
  seenit: { admin: { registeredFeatures = [] } = {} } = {},
} = {}) => ({ registeredFeatures });
