import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, ActionCard, Typography } from '@team-seenit/atoms';
import { FormControl } from '@team-seenit/molecules';
import { useForm } from '@team-seenit/contexts';

import { useMutation } from '@apollo/client';

import { CREATE_TOGGLE_MUTATION } from './CreateToggle.gql';
import * as Styled from './CreateToggle.styled';

export const CreateToggle = ({ dataEl, creatingToggle }) => {
  const [creating, setCreating] = useState(false);

  const [createToggleFunction, { data, error }] = useMutation(
    CREATE_TOGGLE_MUTATION
  );

  const status = 'status';
  const name = 'name';
  const description = 'description';
  const formProps = useForm({
    initialFields: {
      [name]: {},
      [description]: { value: 'None', valid: true },
      [status]: { value: false, valid: true },
    },
  });

  const {
    fields,
    getValue,
    handleSubmit,
    isFormValid,
    handleChange,
    isFieldValid,
    resetAllValues,
  } = formProps;

  const createToggleHandler = () => {
    const toggleDetails = Object.keys(fields).reduce(
      (accummulatedObject, key) => {
        return { ...accummulatedObject, [key]: getValue(key) };
      },
      {}
    );

    createToggleFunction({ variables: { toggleDetails } });
  };

  useEffect(() => {
    if (data) {
      resetAllValues();
      setCreating(false);
      creatingToggle(false);
    }
  }, [data]);

  return (
    <>
      {!creating && (
        <Styled.CreateButtonWrapper dataEl={dataEl}>
          <ActionCard
            cardStyle="ghost"
            cardStyleHover="ghostHover"
            height="128px"
            icon="plus"
            mainText="Create a new toggle"
            onClick={() => {
              setCreating(true);
              creatingToggle(true);
            }}
            width="128px"
          />
        </Styled.CreateButtonWrapper>
      )}
      {creating && (
        <>
          {error && (
            <Typography color="red" variant="header">
              Service error please try again later
            </Typography>
          )}

          <FormControl
            dataEl="NameFormControl"
            hintStyle="inline"
            inputProps={{
              placeholder: 'Enter toggle name here...',
              dataEl: 'NameFormControlInput',
              id: name,
              onChange: handleChange,
              valid: isFieldValid(name),
              value: getValue(name),
            }}
            question="Name"
            type="text"
            validateOn="change"
            validateOnMount
            validators={[
              {
                errorMessage: 'Your toggle needs a name',
                validator: 'required',
              },
              {
                errorMessage: 'Toggle name must be at least 2 characters long',
                validator: 'minLength',
                min: 2,
              },
            ]}
          />
          <FormControl
            dataEl="ShowFeedControl"
            hint="When turned on, the feature will be available"
            hintStyle="inline"
            inputProps={{
              id: status,
              onChange: handleChange,
              valid: isFieldValid(status),
              value: getValue(status),
              readOnly: false,
            }}
            question="Switch toggle"
            type="switch"
            validateOn="change"
            validateOnMount
          />
          <Button
            disabled={!isFormValid}
            onClick={handleSubmit(createToggleHandler)}
          >
            Create toggle
          </Button>
        </>
      )}
    </>
  );
};

CreateToggle.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Set data-el attribute. */
  creatingToggle: PropTypes.bool,
};

CreateToggle.defaultProps = {
  dataEl: 'CreateToggle',
  creatingToggle: false,
};
