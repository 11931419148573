import styled from 'styled-components';

export const LineWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const TitleWrapper = styled.div(() => ({
  width: '35%',
}));

export const BodyWrapper = styled.div(() => ({
  width: '80%',
}));
