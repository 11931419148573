import { gql } from '@apollo/client';

import { QuickEditOrganisationConfigDetails } from '../../fragments';

export const GET_THEME_QUERY = gql`
  query ThemeCurrentState($quickEditThemeId: ID!) {
    seenit {
      quickEditTheme(id: $quickEditThemeId) {
        quickEditThemeId
        name
        orgId
      }
    }
  }
`;

export const GET_ORGANISATIONS_THEMES_QUERY = gql`
  query ThemesCurrentState($orgId: ID!) {
    seenit {
      quickEditThemes(orgId: $orgId) {
        quickEditThemeId
        name
      }
    }
  }
`;

export const GET_ORGANISATIONS_STATS_QUERY = gql`
  query MediaCurrentState($orgId: ID!) {
    seenit {
      organisation(orgId: $orgId) {
        ...QuickEditOrganisationConfigDetails
      }
    }
  }

  ${QuickEditOrganisationConfigDetails}
`;

export const DELETE_QUICK_EDIT_MEDIUM_MUTATION = gql`
  mutation DeleteQuickMedium($quickEditMediumId: ID!) {
    seenit {
      deleteQuickEditMedium(id: $quickEditMediumId) {
        success
      }
    }
  }
`;

export const DELETE_QUICK_EDIT_THEME_MUTATION = gql`
  mutation DeleteQuickEditTheme($quickEditThemeId: ID!) {
    seenit {
      deleteQuickEditTheme(id: $quickEditThemeId) {
        success
      }
    }
  }
`;

export const UPDATE_QUICK_EDIT_MEDIA_MUTATION = gql`
  mutation UpdateQuickEditMedia(
    $updateQuickEditMediumId: ID!
    $data: UpdateQuickEditMediumInput!
  ) {
    seenit {
      updateQuickEditMedium(id: $updateQuickEditMediumId, data: $data) {
        quickEditMediaId
        title
      }
    }
  }
`;

export const destructureDataForOrgStats = (data = {}, currentTab) => {
  const {
    seenit: {
      organisation: {
        name: orgName,
        nickname,
        avatar,
        tier: { name } = {},
        quickEditsConfig = {},
      } = {},
    } = {},
  } = data;
  const { [currentTab]: responseTab = [] } = quickEditsConfig;

  return {
    orgFullName: `${orgName} ${nickname || ''}`,
    avatar,
    name,
    icon: 'brand_editor',
    title: 'Total',
    stats: responseTab.length || 0,
    color: 'blue',
    items: responseTab || [],
  };
};

export const destructureDataForOrgThemes = (data = {}) => {
  const { seenit: { quickEditThemes = [] } = {} } = data;

  return {
    name: 'test',
    icon: 'brand_editor',
    title: 'Total',
    stats: quickEditThemes.length || 0,
    color: 'blue',
    items: quickEditThemes || [],
  };
};
