import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '@team-seenit/atoms';
import { StatsCard } from '@team-seenit/molecules';
import { useFormContext } from 'react-hook-form';
import * as Styled from '../EditorOrgAssets.styled';
import { mutations } from '../EditorOrgAssets.gql';
import { CurrentStateActionCard } from '../../CurrentStateActionCard';
import { EditorOrgAssetsCurrentStateProps } from './EditorOrgAssetsCurrentState.types';

export const EditorOrgAssetsCurrentState = ({
  setSelectedAsset,
  selectedAsset,
  editorOrgAssets,
  refetchAssets,
}: EditorOrgAssetsCurrentStateProps) => {
  const { reset } = useFormContext();

  const [deleteEditorOrgAsset] = useMutation(mutations.deleteEditorOrgAsset, {
    onCompleted: () => {
      refetchAssets();
      setSelectedAsset(null);
    },
  });

  return (
    <Styled.Wrapper>
      <StatsCard
        color="blue"
        icon="brand_editor"
        stats={editorOrgAssets?.length}
        title="Total"
      />
      <Button
        onClick={() => {
          reset();
          setSelectedAsset(null);
        }}
      >
        Reset form / Create editor asset
      </Button>
      {editorOrgAssets?.map(item => {
        return (
          <CurrentStateActionCard
            key={item.editorOrgAssetId}
            id={item.editorOrgAssetId}
            onDeleteClick={() =>
              deleteEditorOrgAsset({
                variables: { editorOrgAssetId: item.editorOrgAssetId },
              })
            }
            disabled={selectedAsset === item.editorOrgAssetId}
            name={item.name}
            onCardClick={() => setSelectedAsset(item.editorOrgAssetId)}
          />
        );
      })}
    </Styled.Wrapper>
  );
};
