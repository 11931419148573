import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  gap: '20px',
}));

export const FormWrapper = styled.div(() => ({}));

export const TabSideWrapper = styled.div(() => ({
  flex: 1,
}));

export const InputFields = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const InputField = styled.div(() => ({}));

export const SwitchWrapper = styled.div(() => ({
  display: 'flex',
  gap: '10px',
}));

export const InnerFormWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const SelectWrapper = styled.div(() => ({
  display: 'flex',
  gap: '10px',

  '> *': {
    ':first-child': {
      width: '100%',
    },
    ':last-child': {
      alignItems: 'center',
    },
  },
}));
