import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useMutation, useLazyQuery } from '@apollo/client';

import { FeatureBriefPresenter } from './FeatureBriefPresenter';

import {
  UPDATE_REGISTERED_FEATURES_MUTATION,
  DELETE_REGISTERED_FEATURES_MUTATION,
  GET_REGISTERED_FEATURE_BY_ID_QUERY,
} from './Brief.gql';

import { prepDefaultFormProps } from './helpers';

export function EditFeature({
  getValue,
  handleFormChanges,
  userData,
  setSubmitted,
  submitted,
  isformValid,
  setUserData,
}): JSX.Element {
  const { push } = useHistory();
  const { registeredFeatureId } = useParams();
  const [currentAction, setCurrentAction] = useState();

  const [
    getFeatureById,
    { data: featureData, error: fetchError },
  ] = useLazyQuery(GET_REGISTERED_FEATURE_BY_ID_QUERY);

  const [
    updateFeature,
    { data: updateFeatureData, error: updateFeatureError },
  ] = useMutation(UPDATE_REGISTERED_FEATURES_MUTATION);

  const [
    deleteFeature,
    { data: deleteFeatureData, error: deleteFeatureError },
  ] = useMutation(DELETE_REGISTERED_FEATURES_MUTATION);

  const preAction = action => {
    setSubmitted(true);
    setCurrentAction(action);
  };

  const postAction = () => {
    setSubmitted(false);
  };

  useEffect(() => {
    if (!featureData) return;

    const {
      seenit: { admin: { registeredFeature = {} } = {} } = {},
    } = featureData;

    setUserData(prepDefaultFormProps(registeredFeature));
  }, [featureData]);

  useEffect(() => {
    if (registeredFeatureId)
      getFeatureById({
        variables: { registeredFeatureId },
      });
  }, [registeredFeatureId]);

  useEffect(() => {
    if (deleteFeatureData) {
      if (!deleteFeatureError) push(`/tiers/features`);
    }
  }, [deleteFeatureData, deleteFeatureError]);

  useEffect(() => {
    if (updateFeatureError || deleteFeatureError) {
      postAction();
    }
  }, [updateFeatureError, deleteFeatureError]);

  useEffect(() => {
    if (updateFeatureData) {
      postAction();
    }
  }, [updateFeatureData]);

  const handleFormSubmission = () => {
    preAction('update');
    updateFeature({
      variables: {
        registeredFeatureId,
        updateRegisteredFeature: {
          description: userData.description.value,
          valueIndicator: userData.valueIndicator.value,
        },
      },
    });
  };

  const handleDeleteClick = () => {
    preAction('delete');
    if (registeredFeatureId)
      deleteFeature({
        variables: {
          registeredFeatureId,
        },
      });
  };

  const currentError = () => {
    if (currentAction === 'update') return updateFeatureError;
    if (currentAction === 'delete') return deleteFeatureError;

    return undefined;
  };

  return (
    <FeatureBriefPresenter
      buttonTitle="Update feature"
      dataEl="EditFeature"
      errors={currentError()}
      fetchError={fetchError}
      getValue={getValue}
      handleDeleteClick={handleDeleteClick}
      handleFormChanges={handleFormChanges}
      handleFormSubmission={handleFormSubmission}
      isformValid={isformValid}
      registeredFeatureId={registeredFeatureId}
      submitted={submitted}
      title="Editing feature"
    />
  );
}

EditFeature.propTypes = {
  /** Function to get field value. */
  getValue: PropTypes.func.isRequired,
  /** Function to handle form changes. */
  handleFormChanges: PropTypes.func.isRequired,
  /** Form data */
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
  /** Function to set field submitted. */
  setSubmitted: PropTypes.func.isRequired,
  /** Boolean field of the state of the form */
  submitted: PropTypes.bool.isRequired,
  /** Boolean field to say the form is valid */
  isformValid: PropTypes.bool.isRequired,
  /** Function to set form data */
  setUserData: PropTypes.func.isRequired,
};
