import { gql } from '@apollo/client';

export const TierDetails = gql`
  fragment TierDetails on Tier {
    __typename
    tierId
    name
    description
    docType
    createdAt
    updatedAt
    registeredFeatures {
      docType
      featureValueId
      createdAt
      updatedAt
      value
      registeredFeature {
        registeredFeatureId
        createdAt
        createdBy
        description
        docType
        name
        updatedAt
        valueIndicator
      }
    }
  }
`;
