export const prepareEditorMediaSelect = data => {
  const { seenit: { editorMedia = [] } = {} } = data || {};

  const defaultMedia = {
    value: '-1',
    text: 'Select from the list or leave this to remove media',
  };

  return [
    defaultMedia,
    ...editorMedia.map(({ editorMediaId, title }) => ({
      value: editorMediaId,
      text: title || editorMediaId,
    })),
  ];
};

export const prepareShareableBrandsSelect = data => {
  const { seenit: { adminShareableBrands = [] } = {} } = data || {};

  const defaultBrand = {
    value: '-1',
    text: 'Select from the list to add a brand',
  };

  return [
    defaultBrand,
    ...adminShareableBrands.map(({ brandId, name }) => ({
      value: brandId,
      text: name || brandId,
    })),
  ];
};
