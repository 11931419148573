import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { Button, Typography, Icon } from '@team-seenit/atoms';
import { FormControl, StaticPage } from '@team-seenit/molecules';
import { Error, TierFeature } from '../../../molecules';

import * as Styled from './Brief.styled';

export function TierBriefPresenter({
  fetchError,
  tierId,
  dataEl,
  errors,
  title,
  buttonTitle,
  handleFormChanges,
  getValue,
  isformValid,
  submitted,
  handleFormSubmission,
  handleDeleteClick,
  registeredFeatures,
  tier,
  tierOrganisations,
}): JSX.Element {
  const { push } = useHistory();

  if (fetchError) {
    return (
      <StaticPage
        body={`Tier with id ${tierId}`}
        buttonProps={{
          children: 'Back to tier list',
          dataEl: 'DataEl',
          onClick: () => push('/tiers'),
        }}
        dataEl="LibraryLoading"
        emoticonProps={{
          dataEl: 'LibraryLoadingEmoji',
          emoji: null,
          emojiComponent: (
            <Icon
              color="red"
              dataEl="LibraryLoadingIcon"
              name="error"
              size="massive"
            />
          ),
        }}
        title="Error fetching data for"
      />
    );
  }

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Error rawErrors={errors} />

      <Typography dataEl="CreateTier" variant="body">
        {title}
      </Typography>
      <FormControl
        dataEl="CreateTierName"
        inputProps={{
          placeholder: 'Enter Tier name...',
          dataEl: 'CreateTierNameTextInput',
          id: 'name',
          onChange: handleFormChanges,
          value: getValue('name').value,
          valid: getValue('name').valid,
        }}
        type="text"
        validateOn="change"
        validators={[
          {
            errorMessage: 'Sorry, but we’re going to need a tier name.',
            validator: 'required',
          },
          {
            errorMessage:
              'Sorry, but we’re going to need a little more than that.',
            validator: 'minLength',
            min: 2,
          },
        ]}
      />
      <FormControl
        dataEl="CreateTierDescription"
        inputProps={{
          placeholder: 'Enter Tier description...',
          dataEl: 'CreateTierDescriptionTextInput',
          id: 'description',
          onChange: handleFormChanges,
          value: getValue('description').value,
          valid: getValue('description').valid,
        }}
        type="text"
        validateOn="change"
        validators={[
          {
            errorMessage: 'Sorry, but we’re going to need a tier description.',
            validator: 'required',
          },
          {
            errorMessage:
              'Sorry, but we’re going to need a little more than that.',
            validator: 'minLength',
            min: 2,
          },
        ]}
      />

      {!!registeredFeatures.length && (
        <>
          <Typography dataEl="CreateTier" variant="title">
            Registered Features
          </Typography>

          {registeredFeatures.map(feat => {
            return (
              <TierFeature
                key={feat.registeredFeatureId}
                getValue={getValue}
                handleFormChanges={handleFormChanges}
                item={{
                  registeredFeatureId: `feature-${feat.registeredFeatureId}`,
                  name: feat.name,
                  description: feat.description,
                  valueIndicator: feat.valueIndicator,
                  dataEl: `Feature-${feat.registeredFeatureId}-DataEl`,
                }}
              />
            );
          })}
        </>
      )}

      <Styled.Buttons>
        <Button
          disabled={!isformValid()}
          loading={submitted}
          onClick={handleFormSubmission}
          rounded
        >
          {buttonTitle}
        </Button>

        {tierId && (
          <Button
            backgroundColor="red"
            loading={submitted}
            onClick={handleDeleteClick}
            rounded
          >
            Delete tier
          </Button>
        )}

        <Button
          backgroundColor="yellow"
          loading={submitted}
          onClick={() => push(`/tiers`)}
          rounded
        >
          Return to Tiers list
        </Button>
      </Styled.Buttons>

      {tier && (
        <>
          <Button backgroundColor="purple" large onClick={tierOrganisations}>
            Maintain organisations for this tier
          </Button>
        </>
      )}
    </Styled.Wrapper>
  );
}

TierBriefPresenter.propTypes = {
  /** DataEl for the component. */
  dataEl: PropTypes.string,
  /** DataEl for the component. */
  tierId: PropTypes.string,
  /** Component title */
  title: PropTypes.string.isRequired,
  /** Component button title */
  buttonTitle: PropTypes.string.isRequired,
  /** Function to get field value. */
  getValue: PropTypes.func.isRequired,
  /** Function to handle form changes. */
  handleFormChanges: PropTypes.func.isRequired,
  /** Function to handle form submission. */
  handleFormSubmission: PropTypes.func.isRequired,
  /** Function to handle delete. */
  handleDeleteClick: PropTypes.func.isRequired,
  /** GQL fetch errors */
  fetchError: PropTypes.objectOf(PropTypes.any),
  /** GQL fetch errors */
  registeredFeatures: PropTypes.objectOf(PropTypes.any),
  /** GQL errors */
  errors: PropTypes.objectOf(PropTypes.any),
  /** Boolean field of the state of the form */
  submitted: PropTypes.bool,
  /** Boolean field to say the form is valid */
  isformValid: PropTypes.func.isRequired,
  /** Boolean field to say the form is valid */
  tierOrganisations: PropTypes.func.isRequired,
  /** Boolean field to say the form is valid */
  tier: PropTypes.objectOf(PropTypes.any),
};

TierBriefPresenter.defaultProps = {
  dataEl: 'TierBriefPresenter',
  errors: undefined,
  fetchError: undefined,
  tierId: undefined,
  registeredFeatures: [],
  submitted: false,
  tier: undefined,
};
