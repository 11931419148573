import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  background: yup.object().shape(
    {
      editorMedia: yup.string().required('Editor image id is required'),
      colorByReference: yup
        .string()
        .nullable()
        .test(
          'only-one-field',
          'Only one color field can have a value',
          (value, context) => {
            const { colorByText } = context.parent;
            return !(value && colorByText);
          }
        ),
      colorByText: yup
        .string()
        .nullable()
        .matches(/^#([a-fA-F0-9]{6})$/, {
          message: 'Must be a valid hex color (e.g., #FFFFFF)',
          excludeEmptyString: true,
        })
        .test(
          'only-one-field',
          'Only one color field can have a value',
          (value, context) => {
            const { colorByReference } = context.parent;
            return !(value && colorByReference);
          }
        ),
      duration: yup
        .number()
        .required('Duration is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      fadeIn: yup
        .number()
        .nullable()
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      fadeInRequired: yup.boolean().nullable(),
      fadeOut: yup
        .number()
        .nullable()
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      fadeOutRequired: yup.boolean().nullable(),
      height: yup
        .number()
        .required('Height is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      positionX: yup
        .number()
        .required('positionX is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      positionY: yup
        .number()
        .required('positionY is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      minWidth: yup
        .number()
        .required('minWidth is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      widthCanGrow: yup.boolean(),
    },
    ['editorMedia', 'colorByReference', 'colorByText']
  ),
  primaryText: yup.object({
    fontSize: yup
      .number()
      .required('fontSize is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    colorByReference: yup
      .string()
      .nullable()
      .test(
        'exactly-one-field',
        'Exactly one color field must have a value',
        (value, context) => {
          const { colorByText } = context.parent;
          return (value && !colorByText) || (!value && colorByText);
        }
      ),
    colorByText: yup
      .string()
      .nullable()
      .matches(/^#([a-fA-F0-9]{6})$/, {
        message: 'Must be a valid hex color (e.g., #FFFFFF)',
        excludeEmptyString: true,
      })
      .test(
        'exactly-one-field',
        'Exactly one color field must have a value',
        (value, context) => {
          const { colorByReference } = context.parent;
          return (value && !colorByReference) || (!value && colorByReference);
        }
      ),
    font: yup.string().required('font is required'),
    positionX: yup
      .number()
      .required('positionX is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positionY: yup
      .number()
      .required('positionY is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positonYWhenNoSecondaryText: yup
      .number()
      .required('positonYWhenNoSecondaryText is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
  }),
  secondaryText: yup.object({
    fontSize: yup
      .number()
      .required('fontSize is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    colorByReference: yup
      .string()
      .nullable()
      .test(
        'exactly-one-field',
        'Exactly one color field must have a value',
        (value, context) => {
          const { colorByText } = context.parent;
          return (value && !colorByText) || (!value && colorByText);
        }
      ),
    colorByText: yup
      .string()
      .nullable()
      .matches(/^#([a-fA-F0-9]{6})$/, {
        message: 'Must be a valid hex color (e.g., #FFFFFF)',
        excludeEmptyString: true,
      })
      .test(
        'exactly-one-field',
        'Exactly one color field must have a value',
        (value, context) => {
          const { colorByReference } = context.parent;
          return (value && !colorByReference) || (!value && colorByReference);
        }
      ),
    font: yup.string().required('font is required'),
    positionX: yup
      .number()
      .required('positionX is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positionY: yup
      .number()
      .required('positionY is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
  }),
});
