import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';

import { FeatureBriefPresenter } from './FeatureBriefPresenter';
import { CREATE_REGISTERED_FEATURES_MUTATION } from './Brief.gql';

export function CreateFeature({
  getValue,
  handleFormChanges,
  userData,
  setSubmitted,
  submitted,
  isformValid,
}): JSX.Element {
  const { push } = useHistory();

  const [
    createFeature,
    { data: createFeatureData, error: createFeatureError },
  ] = useMutation(CREATE_REGISTERED_FEATURES_MUTATION);

  const handleFormSubmission = () => {
    setSubmitted(true);
    createFeature({
      variables: {
        createRegisteredFeature: {
          description: userData.description.value,
          name: userData.name.value,
          valueIndicator: userData.valueIndicator.value,
        },
      },
    });
  };

  useEffect(() => {
    const { seenit: { createRegisteredFeature } = {} } =
      createFeatureData || {};

    if (createRegisteredFeature?.registeredFeatureId) {
      setSubmitted(false);
      push(`/tiers/features`);
    }
  }, [createFeatureData]);

  useEffect(() => {
    if (createFeatureError) {
      setSubmitted(false);
    }
  }, [createFeatureError]);

  return (
    <FeatureBriefPresenter
      buttonTitle="Create feature"
      dataEl="CreateFeature"
      errors={createFeatureError}
      getValue={getValue}
      handleFormChanges={handleFormChanges}
      handleFormSubmission={handleFormSubmission}
      isformValid={isformValid}
      submitted={submitted}
      title="Creating a new feature"
    />
  );
}

CreateFeature.propTypes = {
  /** Function to get field value. */
  getValue: PropTypes.func.isRequired,
  /** Function to handle form changes. */
  handleFormChanges: PropTypes.func.isRequired,
  /** Form data */
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
  /** Function to set field submitted. */
  setSubmitted: PropTypes.func.isRequired,
  /** Boolean field of the state of the form */
  submitted: PropTypes.bool.isRequired,
  /** Boolean field to say the form is valid */
  isformValid: PropTypes.bool.isRequired,
};
