import React from 'react';
import { Button } from '@team-seenit/atoms';
import { StatsCard } from '@team-seenit/molecules';
import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import * as Styled from '../LowerThirds.styled';
import { mutations } from '../LowerThirds.gql';
import { LowerThirdsCurrentStateProps } from './LowerThirdsCurrentState.types';
import { CurrentStateActionCard } from '../../CurrentStateActionCard';

export const LowerThirdsCurrentState = ({
  selectedLowerThird,
  setSelectedLowerThird,
  lowerThirds,
  refetchLowerThirds,
}: LowerThirdsCurrentStateProps) => {
  const [deleteLowerThird] = useMutation(mutations.deleteLowerThird, {
    onCompleted: () => refetchLowerThirds(),
  });

  const { reset } = useFormContext();

  const handleDelete = (lowerThirdId: string) => {
    deleteLowerThird({ variables: { lowerThirdId } });
  };

  return (
    <Styled.Wrapper>
      <StatsCard
        color="blue"
        icon="brand_editor"
        stats={lowerThirds?.length}
        title="Total"
      />

      <Button
        onClick={() => {
          reset();
          setSelectedLowerThird(null);
        }}
      >
        Reset form / Create lower third
      </Button>
      {lowerThirds?.map(item => {
        return (
          <CurrentStateActionCard
            key={item.lowerThirdId}
            id={item.lowerThirdId}
            onDeleteClick={() => handleDelete(item.lowerThirdId)}
            disabled={selectedLowerThird === item.lowerThirdId}
            name={item.name}
            onCardClick={() => setSelectedLowerThird(item.lowerThirdId)}
          />
        );
      })}
    </Styled.Wrapper>
  );
};
