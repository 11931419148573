import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
}));

export const LineItem = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '4px 0 4px 0',
  alignItems: 'center',
  columnGap: '20px',
}));

export const LineWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const TitleWrapper = styled.div(() => ({
  width: '35%',
}));

export const BodyWrapper = styled.div(() => ({
  width: '80%',
}));
