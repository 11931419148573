import React from 'react';
import { Typography, HR, UnorderedList } from '@team-seenit/atoms';
import * as Styled from './FormErrors.styled';

const computeError = (errors: any) => {
  return Object.entries(errors).map(([key, value]) => (
    <>
      {typeof value?.message === 'string' ? (
        <Styled.ListItem>
          <Typography color="red">{value.message}</Typography>
        </Styled.ListItem>
      ) : (
        <>
          <br />
          <HR />
          <br />
          <Typography variant="subheader">{key}</Typography>
          {computeError(value)}
        </>
      )}
    </>
  ));
};

export const FormErrors = ({ errors }: { errors: any }) => {
  return Object.keys(errors)?.length ? (
    <Styled.Wrapper>
      <Typography variant="header">Errors</Typography>
      <br />
      <UnorderedList>{computeError(errors)}</UnorderedList>
    </Styled.Wrapper>
  ) : null;
};
