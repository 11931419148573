import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
}));

// export const CardContentWrapper = styled.div(() => ({
//   display: 'flex',
//   flexDirection: 'column',
// }));

// export const TabContentWrapper = styled.div(() => ({
//   display: 'flex',
// }));

export const TabSideWrapper = styled.div(() => ({
  flex: 1,
  marginRight: '20px',
}));
