import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
}));

export const TabSideWrapper = styled.div(() => ({
  flex: 1,
  marginBottom: '10px',
}));
