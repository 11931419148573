import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  DELETE_TIER_MUTATION,
  GET_TIER_BY_ID_QUERY,
  UPDATE_TIER_MUTATION,
} from './Brief.gql';

import { TierBriefPresenter } from './TierBriefPresenter';
import { prepEditFormProps } from './helpers';

export function EditTier({
  getValue,
  handleFormChanges,
  isformValid,
  setSubmitted,
  submitted,
  userData,
  setUserData,
  registeredFeatures,
}: TiersOrgProps): JSX.Element {
  const { push } = useHistory();
  const { tierId } = useParams();
  const [tierCombined, setTierCombined] = useState();

  const [
    getTierById,
    { data: tierData, error: fetchError, loading },
  ] = useLazyQuery(GET_TIER_BY_ID_QUERY);

  const [
    updateTier,
    { data: updateTierData, error: updateTierError },
  ] = useMutation(UPDATE_TIER_MUTATION);

  const [
    deleteTier,
    { data: deleteTierData, error: deleteTierError },
  ] = useMutation(DELETE_TIER_MUTATION);

  const { seenit: { admin: { tier = {} } = {} } = {} } = tierData || {};

  useEffect(() => {
    if (!tierData) return;

    setTierCombined(tier);
  }, [tierData]);

  useEffect(() => {
    if (!tierCombined?.tierId) return;

    setUserData(prepEditFormProps(tierCombined, registeredFeatures));
  }, [tierCombined]);

  useEffect(() => {
    if (tierId)
      getTierById({
        variables: { tierId },
      });
  }, [tierId]);

  useEffect(() => {
    if (updateTierError || deleteTierError) {
      setSubmitted(false);
    }
  }, [updateTierError, deleteTierError]);

  useEffect(() => {
    if (updateTierData) {
      setSubmitted(false);
      const { seenit: { updateTier: updatedTier = {} } = {} } = updateTierData;

      setTierCombined({ ...updatedTier });
    }
  }, [updateTierData]);

  useEffect(() => {
    if (deleteTierData && !deleteTierError) {
      setSubmitted(false);
      push(`/tiers`);
    }
  }, [deleteTierData]);

  const actionPicker = (featureEnabled, featureValueId) => {
    if (!featureEnabled && featureValueId) return 'delete';
    if (featureEnabled && featureValueId) return 'update';
    if (featureEnabled && !featureValueId) return 'create';
  };

  const buildFeatureValue = () => {
    return Object.keys(userData)
      .map(key => {
        if (key.startsWith('feature-') && key.endsWith('-enable')) {
          const registeredFeatureId = key
            .replace('feature-', '')
            .replace('-enable', '');

          const { value: featureEnabled } = userData[key];

          const { featureValueId, value: selectedValue } = userData[
            `feature-${registeredFeatureId}-value`
          ];

          if (!featureEnabled && !featureValueId) return;

          return {
            action: actionPicker(featureEnabled, featureValueId),
            featureValueId,
            registeredFeatureId,
            value: selectedValue.toString(),
          };
        }
        return false;
      })
      .filter(Boolean);
  };

  const handleFormSubmission = () => {
    setSubmitted(true);
    updateTier({
      variables: {
        tierId,
        updateTier: {
          description: userData.description.value,
          name: userData.name.value,
          registeredFeatureValues: buildFeatureValue(),
        },
      },
    });
  };

  const handleDeleteClick = () => {
    setSubmitted(true);
    if (tierId)
      deleteTier({
        variables: {
          tierId,
        },
      });
  };

  if (loading || !tierCombined?.tierId) return 'Loading.... Please wait';
  if (fetchError) return 'Error fetching tier';

  return (
    <TierBriefPresenter
      buttonTitle="Update tier"
      dataEl="EditTier"
      errors={updateTierError || deleteTierError}
      fetchError={fetchError}
      getValue={getValue}
      handleDeleteClick={handleDeleteClick}
      handleFormChanges={handleFormChanges}
      handleFormSubmission={handleFormSubmission}
      isformValid={isformValid}
      registeredFeatures={registeredFeatures}
      submitted={submitted}
      tier={tierCombined}
      tierId={tierId}
      tierOrganisations={() => push(`/tiers/${tierId}/organisations`)}
      title="Editing tier"
    />
  );
}
