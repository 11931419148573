export const defaultFormProps = {
  name: { value: undefined, valid: true, required: true },
  description: { value: undefined, valid: true, required: true },
};

export const prepDefaultFormProps = ({
  name,
  description,
  registeredFeatureValues = [],
}) => {
  return {
    name: { value: name, valid: true, required: true },
    description: { value: description, valid: true, required: true },

    ...((!!registeredFeatureValues &&
      registeredFeatureValues.reduce((previous, feature) => {
        return {
          ...previous,
          [`feature-${feature.registeredFeatureId}-value`]: {
            value: feature.valueIndicator === 'boolean' ? false : undefined,
            valid: true,
            required: false,
          },
          [`feature-${feature.registeredFeatureId}-enable`]: {
            value: false,
            valid: true,
            required: false,
          },
        };
      }, {})) ||
      {}),
  };
};

const convertValue = exists => {
  if (exists.registeredFeature.valueIndicator === 'boolean')
    return ['true'].includes(exists.value.toLowerCase());
  if (exists.registeredFeature.valueIndicator === 'number') {
    return Number(exists.value);
  }

  return exists.value;
};

const featureExists = (feature, registeredFeatureValues) => {
  const exists =
    !!registeredFeatureValues &&
    registeredFeatureValues.find(
      ({ registeredFeature: { registeredFeatureId } = {} } = {}) =>
        registeredFeatureId === feature.registeredFeatureId
    );

  return exists ? { ...exists, value: convertValue(exists) } : { value: false };
};

export const prepEditFormProps = (
  { name, description, registeredFeatures: registeredFeatureValues = [] },
  registeredFeatures = []
) => {
  const existingFeatures = !registeredFeatureValues
    ? []
    : registeredFeatureValues.map(
        ({ registeredFeature: { registeredFeatureId } = {} } = {}) =>
          registeredFeatureId
      );

  return {
    name: { value: name, valid: true, required: true },
    description: { value: description, valid: true, required: true },

    ...((!!registeredFeatures &&
      registeredFeatures.reduce((previous, feature) => {
        return {
          ...previous,
          [`feature-${feature.registeredFeatureId}-value`]: {
            ...featureExists(feature, registeredFeatureValues),
            valid: true,
            required: false,
          },
          [`feature-${feature.registeredFeatureId}-enable`]: {
            value: existingFeatures.includes(feature.registeredFeatureId),
            valid: true,
            required: false,
          },
        };
      }, {})) ||
      {}),
  };
};
