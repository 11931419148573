import { gql } from '@apollo/client';

export const GET_ORGANISATIONS_QUERY = gql`
  query AdminGetAllOrganisations($orgType: OrganisationTypeEnum) {
    seenit {
      admin {
        organisations(orgType: $orgType) {
          orgId
          name
          nickname
        }
      }
    }
  }
`;

const defaultOrg = { text: 'Please select an organisation', value: '-1' };

const orgFullName = (name, nickname) =>
  nickname ? `${name} - ${nickname}` : name;

export const destructureDataForOrganisations = ({
  seenit: { admin: { organisations = [] } = {} } = {},
} = {}) => {
  const organisationSelector = organisations.map(org => ({
    text: orgFullName(org.name, org.nickname),
    value: org.orgId,
  }));
  return {
    organisations,
    organisationSelector: [defaultOrg, ...organisationSelector],
  };
};
