import { gql } from '@apollo/client';

export const QuickEditOrganisationConfigDetails = gql`
  fragment QuickEditOrganisationConfigDetails on Organisation {
    __typename
    orgId
    name
    nickname
    avatar
    tier {
      tierId
      name
    }
    quickEditsConfig {
      backgroundImage {
        quickEditMediaId
        title
        status
        publicId
        mediaFormat
        thumbnailUrl
        uploadUrl
        isAudio
      }
      audioTracks {
        quickEditMediaId
        title
        status
        publicId
        mediaFormat
        thumbnailUrl
        uploadUrl
        isAudio
      }
      closingMedia {
        quickEditMediaId
        title
        status
        publicId
        mediaFormat
        thumbnailUrl
        uploadUrl
        isAudio
      }
      fonts {
        quickEditMediaId
        title
        status
        publicId
        mediaFormat
        thumbnailUrl
        uploadUrl
        isAudio
      }
      openingMedia {
        quickEditMediaId
        title
        status
        publicId
        mediaFormat
        thumbnailUrl
        uploadUrl
        isAudio
      }
      watermarks {
        quickEditMediaId
        title
        status
        publicId
        mediaFormat
        thumbnailUrl
        uploadUrl
        isAudio
      }
    }
  }
`;
