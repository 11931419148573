/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typography, Select, TextInput, Switch } from '@team-seenit/atoms';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from '../LowerThirds.styled';
import { queries } from '../LowerThirds.gql';
import {
  prepareMediaForSelect,
  prepareEditorOrgAssetForSelect,
  defaultFormProps,
} from '../helpers';
import { FormError } from '../../FormError';
import { LowerThirdsFormSectionProps } from './LowerThirdsForm.types';
import { isNumber } from '../../helpers';

export const LowerThirdsFormSection = ({
  dataEl,
  section,
  orgId,
}: LowerThirdsFormSectionProps) => {
  const {
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  const [
    getLowerThirdBackgrounds,
    { data: lowerThirdsBackgrounds },
  ] = useLazyQuery(queries.getEditorMedia);

  const [getEditorOrgAssets, { data: editorOrgAssets }] = useLazyQuery(
    queries.getEditorOrgAssets
  );

  const [getFonts, { data: fontsData }] = useLazyQuery(queries.getEditorMedia);

  useEffect(() => {
    if (orgId) {
      getLowerThirdBackgrounds({
        variables: {
          filter: { orgId, mediaType: 'backgroundImage' },
        },
      });
      getFonts({
        variables: {
          filter: { orgId, mediaType: 'fonts' },
        },
      });
      getEditorOrgAssets({ variables: { filter: { orgId } } });
    }
  }, [orgId]);

  const { [section]: sectionData = {} } = defaultFormProps as any;
  const sectionKeys = Object.keys(sectionData);

  return (
    <Styled.Wrapper>
      {sectionKeys.includes('editorMedia') && (
        <Controller
          name={`${section}.editorMedia`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                Editor image Id
              </Typography>
              <Select
                {...field}
                dataEl={dataEl}
                options={prepareMediaForSelect(lowerThirdsBackgrounds)}
                value={field.value ?? -1}
                onChange={val => {
                  if (val === '-1') field.onChange(null);
                  else field.onChange(val);
                  trigger(`${section}.editorMedia`);
                  trigger(`${section}.colorByReference`);
                }}
              />
              <FormError
                errors={errors}
                section={section}
                field="editorMedia"
              />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('font') && (
        <Controller
          name={`${section}.font`}
          key={`${section}.font`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                Font
              </Typography>
              <Select
                {...field}
                checked={field.value}
                dataEl={dataEl}
                options={prepareMediaForSelect(fontsData)}
                value={field.value ?? -1}
              />
              <FormError errors={errors} section={section} field="font" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('fontSize') && (
        <Controller
          name={`${section}.fontSize`}
          key={`${section}.fontSize`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                Font Size
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Font size"
                value={getValues(`${section}.fontSize`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} section={section} field="fontSize" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('positionX') && (
        <Controller
          name={`${section}.positionX`}
          key={`${section}.positionX`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                positionX
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Image positionX"
                value={getValues(`${section}.positionX`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} section={section} field="positionX" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('positionY') && (
        <Controller
          name={`${section}.positionY`}
          key={`${section}.positionY`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                positionY
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Image positionY"
                value={getValues(`${section}.positionY`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} section={section} field="positionY" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('positonYWhenNoSecondaryText') && (
        <Controller
          name={`${section}.positonYWhenNoSecondaryText`}
          key={`${section}.positonYWhenNoSecondaryText`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                positonYWhenNoSecondaryText
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="positonYWhenNoSecondaryText"
                value={
                  getValues(`${section}.positonYWhenNoSecondaryText`) ?? ''
                }
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError
                errors={errors}
                section={section}
                field="positonYWhenNoSecondaryText"
              />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('height') && (
        <Controller
          name={`${section}.height`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                height
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Image height"
                value={getValues(`${section}.height`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} section={section} field="height" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('minWidth') && (
        <Controller
          name={`${section}.minWidth`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                Minimum width
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Min image width"
                id="width"
                value={getValues(`${section}.minWidth`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} section={section} field="minWidth" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('fadeIn') && (
        <Controller
          name={`${section}.fadeIn`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                fadeIn
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="fadeIn"
                id="fadeIn"
                value={getValues(`${section}.fadeIn`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} section={section} field="fadeIn" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('fadeInRequired') && (
        <Controller
          name={`${section}.fadeInRequired`}
          control={control}
          render={({ field }) => (
            <Styled.SwitchWrapper>
              <Switch
                {...field}
                dataEl={dataEl}
                id="fadeInRequired"
                checked={field.value}
              />
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                fadeInRequired
              </Typography>
            </Styled.SwitchWrapper>
          )}
        />
      )}

      {sectionKeys.includes('fadeOut') && (
        <Controller
          name={`${section}.fadeOut`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                Fade out
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="fadeOut"
                id="fadeOut"
                value={getValues(`${section}.fadeOut`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} section={section} field="fadeOut" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('fadeOutRequired') && (
        <Controller
          name={`${section}.fadeOutRequired`}
          control={control}
          render={({ field }) => (
            <Styled.SwitchWrapper>
              <Switch
                {...field}
                dataEl={dataEl}
                id="fadeOutRequired"
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
              />
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                fadeOutRequired
              </Typography>
            </Styled.SwitchWrapper>
          )}
        />
      )}

      {sectionKeys.includes('duration') && (
        <Controller
          name={`${section}.duration`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                duration
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="duration"
                id="duration"
                value={getValues(`${section}.duration`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} section={section} field="duration" />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('colorByReference') && (
        <Controller
          name={`${section}.colorByReference`}
          key={`${section}.colorByReference`}
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                Color (using Editor Org Asset)
              </Typography>
              <Select
                {...field}
                dataEl={dataEl}
                options={prepareEditorOrgAssetForSelect(editorOrgAssets)}
                value={field.value ?? -1}
                onChange={val => {
                  if (val === '-1') field.onChange(null);
                  else field.onChange(val);
                  trigger(`${section}.colorByReference`);
                  trigger(`${section}.editorMedia`);
                }}
              />
              <FormError
                errors={errors}
                section={section}
                field="colorByReference"
              />
            </Styled.InputWrapper>
          )}
        />
      )}

      {sectionKeys.includes('widthCanGrow') && (
        <Controller
          name={`${section}.widthCanGrow`}
          control={control}
          render={({ field }) => (
            <Styled.SwitchWrapper>
              <Switch
                {...field}
                dataEl={dataEl}
                id="widthCanGrow"
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
              />
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                widthCanGrow
              </Typography>
            </Styled.SwitchWrapper>
          )}
        />
      )}
    </Styled.Wrapper>
  );
};
