import { gql } from '@apollo/client';

export const GET_TOGGLE_QUERY = gql`
  query AdminGetAllToggles {
    seenit {
      toggles {
        toggleId
        name
        description
        status
      }
    }
  }
`;

export const UPDATE_TOGGLE_MUTATION = gql`
  mutation AdminUpdateToggleMutation(
    $toggleId: ID!
    $toggleDetails: UpdateToggleInput!
  ) {
    seenit {
      updateToggle(id: $toggleId, toggleDetails: $toggleDetails) {
        toggleId
        name
        description
        status
      }
    }
  }
`;

export const DELETE_TOGGLE_MUTATION = gql`
  mutation AdminDeleteToggleMutation($toggleId: ID!) {
    seenit {
      deleteToggle(id: $toggleId) {
        success
      }
    }
  }
`;

export const destructureDataForToggles = ({
  seenit: { toggles = [] } = {},
} = {}) => toggles;
