import React, { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';

import {
  quickEditOrgStatsDataVar,
  quickEditThemesReloadVar,
  quickEditThemeIdVar,
} from './variables';
import * as Styled from './Themes.styled';
import { ThemesBrief } from './Presenter';
import { ThemesCurrentState } from './CurrentState';

import { GET_ORGANISATIONS_STATS_QUERY } from './Themes.gql';

export const Themes = ({ dataEl, orgId }) => {
  const quickEditThemesReload = useReactiveVar(quickEditThemesReloadVar);

  const [fetchQuickEditOrgStats, { data }] = useLazyQuery(
    GET_ORGANISATIONS_STATS_QUERY
  );

  useEffect(() => {
    quickEditThemesReloadVar('initial');
    quickEditThemeIdVar(undefined);
  }, []);

  useEffect(() => {
    if (orgId && quickEditThemesReload) {
      fetchQuickEditOrgStats({ variables: { orgId } });
    }
  }, [orgId, quickEditThemesReload]);

  useEffect(() => {
    if (data) {
      quickEditOrgStatsDataVar(data);
    }
  }, [data]);

  return (
    <>
      <Styled.TabSideWrapper dataEl={dataEl}>
        <ThemesBrief orgId={orgId} />
      </Styled.TabSideWrapper>

      <Styled.TabSideWrapper>
        <ThemesCurrentState orgId={orgId} />
      </Styled.TabSideWrapper>
    </>
  );
};

Themes.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Org id. */
  orgId: PropTypes.string.isRequired,
};

Themes.defaultProps = {
  dataEl: 'Themes',
};
