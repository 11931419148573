import React from 'react';

import styled from 'styled-components';
import { ErrorBoundary } from '@sentry/react';
import { useAnalytics } from '@team-seenit/contexts';
import { Typography } from '@team-seenit/atoms';
import { StaticPages, StudioTopBar } from '@team-seenit/organisms';
import { StudioTemplate } from '@team-seenit/templates';
import { CONTENTFUL } from '@team-seenit/constants';
import { MenuDesktopHelper } from './components/MenuDesktopHelper';

const {
  KEYS: { PROJECTS_MAIN_ERROR_GRAPHQL },
} = CONTENTFUL;

const Main = styled.main(
  ({ theme: { breakpoints: { min: { desktop = undefined } = {} } = {} } }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 16px',
    width: '100%',
    'z-index': 0,
    [`@media (min-width: ${desktop})`]: {
      padding: '32px',
    },
  })
);

export const LandingPage = () => {
  const { page } = useAnalytics();

  page('ADMIN', 'Admin Landing');

  return (
    <StudioTemplate
      dataEl="AdminPage"
      mainComponent={
        <Main>
          <ErrorBoundary
            fallback={({ eventId }) => (
              <StaticPages.ErrorGraphQL
                contentLocationKey={PROJECTS_MAIN_ERROR_GRAPHQL}
                eventId={eventId}
              />
            )}
          >
            <Typography variant="header">Content goes here</Typography>
            <Typography variant="header">
              Tooltip appears broken in Pluto, hence menu items are just icons
            </Typography>
            <Typography variant="header">Logout using the avatars</Typography>
          </ErrorBoundary>
        </Main>
      }
      menuDesktopComponent={<MenuDesktopHelper />}
      topBarComponent={<StudioTopBar />}
    />
  );
};
