import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '500px',
  border: '1px solid #e0e0e0',
  borderRadius: '20px',
  padding: '20px',
}));

export const InputWrapper = styled.div(() => ({}));
