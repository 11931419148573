const addAddon = (addon, prefix, feature) => {
  if (!addon) return {};

  return {
    [`${prefix}${feature.registeredFeatureId}-startdate`]: {
      value: feature.startDate && new Date(Number(feature.startDate)),
      valid: true,
      required: false,
    },
    [`${prefix}${feature.registeredFeatureId}-enddate`]: {
      value: feature.endDate && new Date(Number(feature.endDate)),
      valid: true,
      required: false,
    },
  };
};

const convertValue = exists => {
  if (exists?.registeredFeature?.valueIndicator === 'boolean')
    return ['true'].includes(exists.value.toLowerCase());

  return undefined;
};

export const prepDefaultFeatures = ({ features, prefix, addon }) => {
  return {
    ...((!!features &&
      features.reduce((previous, feature) => {
        return {
          ...previous,
          [`${prefix}${feature.registeredFeatureId}-value`]: {
            ...feature,
            value: convertValue(feature?.featureValue),
            valid: true,
            required: false,
          },
          [`${prefix}${feature.registeredFeatureId}-enable`]: {
            value: !!feature.featureAddonId,
            valid: true,
            required: false,
          },
          ...addAddon(addon, prefix, feature),
        };
      }, {})) ||
      {}),
  };
};
