import { gql } from '@apollo/client';
import { QuickEditOrganisationConfigDetails } from '../../fragments';

export const GET_ORGANISATIONS_STATS_QUERY = gql`
  query MediaCurrentState($orgId: ID!) {
    seenit {
      organisation(orgId: $orgId) {
        ...QuickEditOrganisationConfigDetails
      }
    }
  }

  ${QuickEditOrganisationConfigDetails}
`;
