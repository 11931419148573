import { gql } from '@apollo/client';

export const queries = {
  getOrgBrands: gql`
    query EditorConfigGetBrands($orgId: ID) {
      seenit {
        brands(orgId: $orgId) {
          name
          brandId
        }
      }
    }
  `,

  getAllBrands: gql`
    query EditorConfigGetAllBrands {
      seenit {
        brands {
          brandId
          name
        }
      }
    }
  `,

  getFloatingOrgs: gql`
    query EditorConfigGetFloatingOrgs(
      $filter: FloatingOrgFilterInput
      $sort: [SortGenericInput]
    ) {
      seenit {
        floatingOrgs(filter: $filter, sort: $sort) {
          name
          orgId
        }
      }
    }
  `,
};

export const mutations = {
  createBrand: gql`
    mutation EditorConfigCreateBrand($data: CreateBrandInput!) {
      seenit {
        createBrand(data: $data) {
          brandId
        }
      }
    }
  `,
  deleteGroup: gql`
    mutation EditorConfigDeleteFloatingOrg($orgId: ID!) {
      seenit {
        deleteFloatingOrg(orgId: $orgId) {
          success
        }
      }
    }
  `,
  updateGroup: gql`
    mutation EditorConfigUpdateFloatingOrg(
      $orgId: ID!
      $data: UpdateFloatingOrgInput!
    ) {
      seenit {
        updateFloatingOrg(orgId: $orgId, data: $data) {
          orgId
        }
      }
    }
  `,
};
