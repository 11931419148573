export const defaultFormProps = {
  name: null,
  orientation: null,
  logo: {
    editorMediaId: null,
    height: null,
    positionX: null,
    positionY: null,
    width: null,
  },
  openingVideos: {},
  closingVideos: {},
  musicTracks: {},
  lowerThirds: {},
};
