import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { FormControl } from '@team-seenit/molecules';
import { Tabs } from '@team-seenit/atoms';
import {
  GET_ORGANISATIONS_QUERY,
  destructureDataForOrganisations,
} from './Organisations.gql';
import * as Styled from './QuickEditsConfig.styled';
import { Themes } from './ThemesSubPageOrganism';
import { MediaConfig } from './MediaConfigOrganism';
import { MediaCurrentState } from './MediaCurrentStateOrganism';

export function QuickEditsConfig({ dataEl }) {
  const [currentOrgId, setCurrentOrgId] = useState<undefined>();
  const [currentTab, setCurrentTab] = useState<undefined>();

  const { data, error } = useQuery(GET_ORGANISATIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { orgType: 'team' },
  });

  if (error) return 'GQL Error';

  const { organisationSelector } = destructureDataForOrganisations(
    data,
    currentOrgId
  );

  const tabs = [
    { dataEl: 'Opening', id: 'opening', value: 'openingMedia' },
    { dataEl: 'Closing', id: 'closing', value: 'closingMedia' },
    { dataEl: 'Audios', id: 'audios', value: 'audioTracks' },
    { dataEl: 'Watermarks', id: 'fonts', value: 'watermarks' },
    { dataEl: 'BackgroundImage', id: 'fonts', value: 'backgroundImage' },
    { dataEl: 'Fonts', id: 'fonts', value: 'fonts' },
    { dataEl: 'Themes', id: 'themes', value: 'themes' },
  ];

  const organisationConfigs = [
    'openingMedia',
    'closingMedia',
    'audioTracks',
    'watermarks',
    'backgroundImage',
    'fonts',
  ];

  const onChange = tab => {
    setCurrentTab(tab);
  };

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <FormControl
        dataEl="Organisations"
        inputProps={{
          dataEl: 'OrganisationsInput',
          id: 'organisations',
          options: organisationSelector,
          onChange: value => setCurrentOrgId(value),
          value: () => console.log(''),
          valid: true,
        }}
        type="select"
        validateOn="change"
      />

      {currentOrgId && currentOrgId !== '-1' && (
        <Styled.CardContentWrapper>
          <Tabs onChange={onChange} tabs={tabs} tabStyle="switch" />

          <Styled.TabContentWrapper>
            {organisationConfigs.includes(currentTab) && (
              <Styled.TabSideWrapper>
                <MediaConfig currentTab={currentTab} orgId={currentOrgId} />{' '}
              </Styled.TabSideWrapper>
            )}
            {organisationConfigs.includes(currentTab) && (
              <Styled.TabSideWrapper>
                <MediaCurrentState
                  currentTab={currentTab}
                  orgId={currentOrgId}
                />{' '}
              </Styled.TabSideWrapper>
            )}

            {['themes'].includes(currentTab) && <Themes orgId={currentOrgId} />}
          </Styled.TabContentWrapper>
        </Styled.CardContentWrapper>
      )}
    </Styled.Wrapper>
  );
}

QuickEditsConfig.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
};

QuickEditsConfig.defaultProps = {
  dataEl: 'QuickEditsConfig',
};
