import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';

import { Button, Card, Grid } from '@team-seenit/atoms';

import { useLazyQuery } from '@apollo/client';

import { GET_TIERS_QUERY, destructureDataForTiers } from './Tiers.gql';
import * as Styled from './Tiers.styled';

import { LineItem } from '../../molecules';

export function Tiers({ dataEl }): JSX.Element {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleCardClick = tierId => {
    push(`/tiers/${tierId}/setup`);
  };

  const [getTiers, { data, error, loading }] = useLazyQuery(GET_TIERS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data) getTiers();
  }, []);

  if (loading) return 'Loading tiers';
  if (error) return <h1>GQL Error</h1>;

  const tiers = destructureDataForTiers(data);

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Button onClick={() => push(`${pathname}/features`)}>
        Maintain features
      </Button>
      <Button onClick={() => push(`${pathname}/create`)}>
        Create a new tier
      </Button>
      <Button onClick={() => push(`${pathname}/organisations`)}>
        Maintain organisations
      </Button>
      <Grid>
        {tiers.map(tier => (
          <Card
            key={tier.tierId}
            cardStyle="ghost"
            cardStyleHover="ghostHover"
            dataEl="Card"
            height="150px"
            onClick={() => handleCardClick(tier.tierId)}
            padding="20px"
            type="card"
            width="400px"
          >
            <Styled.CardContentWrapper>
              <LineItem text={tier.tierId} title="Id" />
              <LineItem text={tier.name} title="Name" />
              <LineItem text={tier.description} title="Description" />
            </Styled.CardContentWrapper>
          </Card>
        ))}
      </Grid>
    </Styled.Wrapper>
  );
}

Tiers.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
};

Tiers.defaultProps = {
  dataEl: 'Tiers',
};
