import React from 'react';
import { MenuDesktop } from '@team-seenit/molecules';
import { useHistory } from 'react-router-dom';
import { LOGOS } from '@team-seenit/constants';
import { useAuth } from '@team-seenit/contexts';

export const MenuDesktopHelper = () => {
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { user: { profile: { firstName = undefined } = {} } = {} } = useAuth();
  const { SEENIT_LOGO_ACCENT } = LOGOS;

  const tiersPath = pathname.startsWith('/tiers') ? '/tiers' : pathname;

  return (
    <MenuDesktop
      bottomLinks={[
        {
          icon: 'help',
          id: 'help',
          onClick: () => console.log('==boom'),
          tooltipText: 'Help',
        },
      ]}
      dataEl="MenuDesktop"
      logoProps={{
        onClick: () => push('/'),
        src: SEENIT_LOGO_ACCENT,
      }}
      orgAvatarProps={{
        onClick: () => push('logout'),
        src: SEENIT_LOGO_ACCENT,
        orgName: 'Pink Wombat',
      }}
      pathname={tiersPath}
      topLinks={[
        {
          icon: 'library',
          id: 'library',
          onClick: () => push('/tiers'),
          tooltipText: 'Library',
          pathname: '/tiers',
        },
        {
          icon: 'album',
          id: 'album',
          onClick: () => push('/toggles'),
          tooltipText: 'Album',
          pathname: '/toggles',
        },
        {
          icon: 'wevideo',
          id: 'wevideo',
          onClick: () => push('/editor'),
          tooltipText: 'WeVideo',
          pathname: '/editor',
        },
        {
          icon: 'tv',
          id: 'tv',
          onClick: () => push('/quick-edits'),
          tooltipText: 'TV',
          pathname: '/quick-edits',
        },
        {
          icon: 'people',
          id: 'people',
          onClick: () => console.log('==boom'),
          tooltipText: 'People',
        },
        {
          icon: 'settings',
          id: 'settings',
          onClick: () => console.log('==boom'),
          tooltipText: 'Settings',
        },
      ]}
      userAvatarProps={{
        userName: firstName,
        onClick: () => push('logout'),
        src: '',
        userEmoji: '',
      }}
    />
  );
};
