/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { EditorOrgAssetsCurrentState } from './CurrentState';
import { EditorOrgAssetsForm } from './Form/EditorOrgAssetsForm';
import * as Styled from './EditorOrgAssets.styled';
import { queries } from './EditorOrgAssets.gql';

export const EditorOrgAssets = ({ orgId }: { orgId: string }) => {
  const [selectedAsset, setSelectedAsset] = useState();
  const methods = useForm({
    mode: 'onSubmit',
  });
  const [
    fetchEditorOrgAssets,
    { data: getEditorOrgAssetsData, refetch },
  ] = useLazyQuery(queries.getEditorOrgAssets);

  useEffect(() => {
    if (orgId) {
      fetchEditorOrgAssets({ variables: { filter: { orgId } } });
    }
  }, [orgId]);

  return (
    <FormProvider {...methods}>
      <Styled.TabSideWrapper>
        <EditorOrgAssetsForm
          setSelectedAsset={setSelectedAsset}
          selectedAsset={selectedAsset}
          orgId={orgId}
          refetchAssets={refetch}
        />
      </Styled.TabSideWrapper>
      <Styled.TabSideWrapper>
        <EditorOrgAssetsCurrentState
          editorOrgAssets={getEditorOrgAssetsData?.seenit?.editorOrgAssets}
          setSelectedAsset={setSelectedAsset}
          selectedAsset={selectedAsset}
          refetchAssets={refetch}
        />
      </Styled.TabSideWrapper>
    </FormProvider>
  );
};
