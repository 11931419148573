import React from 'react';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import { FormControl } from '@team-seenit/molecules';
import { ThemeSectionSelectionsBrief } from './ThemeSectionSelectionsBrief';
import {
  quickEditOrgStatsDataVar,
  quickEditThemeFormDataVar,
} from '../variables';

import * as Styled from './ThemesBrief.styled';
import { defaultFormProps, fetchConfigData } from '../helpers';

export const ThemeSectionBrief = ({ dataEl, section }): JSX.Element => {
  const quickEditThemeFormData = useReactiveVar(quickEditThemeFormDataVar);
  const quickEditOrgStatsData = useReactiveVar(quickEditOrgStatsDataVar);

  const handleChange = (value, others) => {
    const { id, valid } = others;

    quickEditThemeFormDataVar({
      ...quickEditThemeFormData,
      [section]: {
        ...quickEditThemeFormData[section],
        [id]: {
          ...quickEditThemeFormData[section][id],
          value,
          valid,
        },
      },
    });
  };

  const getValue = formKey => {
    return quickEditThemeFormData[section][formKey];
  };

  const { [section]: sectionData = {} } = defaultFormProps;
  const sectionKeys = Object.keys(sectionData);

  if (section === 'selections') {
    return <ThemeSectionSelectionsBrief section={section} />;
  }

  return (
    <Styled.Wrapper>
      {sectionKeys.includes('quickEditMedia') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'quickEditMedia',
            onChange: handleChange,
            value: getValue('quickEditMedia').value,
            valid: getValue('quickEditMedia').valid,
            options: fetchConfigData(quickEditOrgStatsData, section),
          }}
          question="Quick edit image Id"
          type="select"
          validateOn="change"
        />
      )}

      {sectionKeys.includes('fontSize') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'fontSize',
            onChange: handleChange,
            value: getValue('fontSize').value,
            valid: getValue('fontSize').valid,
            placeholder: 'Font size',
          }}
          question="fontSize"
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Only numbers allowed',
              validator: 'pattern',
              regex: /^[0-9]*(\.[0-9]*)?$/,
            },
          ]}
        />
      )}

      {sectionKeys.includes('positionX') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'positionX',
            onChange: handleChange,
            value: getValue('positionX').value,
            valid: getValue('positionX').valid,
            placeholder: 'Image positionX',
          }}
          question="positionX"
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Only numbers allowed',
              validator: 'pattern',
              regex: /^[0-9]*(\.[0-9]*)?$/,
            },
          ]}
        />
      )}

      {sectionKeys.includes('positionY') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'positionY',
            onChange: handleChange,
            value: getValue('positionY').value,
            valid: getValue('positionY').valid,
            placeholder: 'Image positionY',
          }}
          question="positionY"
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Only numbers allowed',
              validator: 'pattern',
              regex: /^[0-9]*(\.[0-9]*)?$/,
            },
          ]}
        />
      )}

      {sectionKeys.includes('positionYWhenNoLine2') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'positionYWhenNoLine2',
            onChange: handleChange,
            value: getValue('positionYWhenNoLine2').value,
            valid: getValue('positionYWhenNoLine2').valid,
            placeholder: 'Image positionYWhenNoLine2',
          }}
          question="positionYWhenNoLine2"
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Only numbers',
              validator: 'pattern',
              regex: /^[0-9]*(\.[0-9]*)?$/,
            },
          ]}
        />
      )}

      {sectionKeys.includes('height') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'height',
            onChange: handleChange,
            value: getValue('height').value,
            valid: getValue('height').valid,
            placeholder: 'Image height',
          }}
          question="height"
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Only numbers allowed',
              validator: 'pattern',
              regex: /^[0-9]*(\.[0-9]*)?$/,
            },
          ]}
        />
      )}

      {sectionKeys.includes('width') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'width',
            onChange: handleChange,
            value: getValue('width').value,
            valid: getValue('width').valid,
            placeholder: 'Image width',
          }}
          question="width"
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Only numbers allowed',
              validator: 'pattern',
              regex: /^[0-9]*(\.[0-9]*)?$/,
            },
          ]}
        />
      )}

      {sectionKeys.includes('fadeIn') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'fadeIn',
            onChange: handleChange,
            value: getValue('fadeIn').value,
            valid: getValue('fadeIn').valid,
            placeholder: 'Time in seconds',
          }}
          question="fadeIn"
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Only numbers allowed',
              validator: 'pattern',
              regex: /^[0-9]*(\.[0-9]*)?$/,
            },
          ]}
        />
      )}

      {sectionKeys.includes('fadeInRequired') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'fadeInRequired',
            onChange: handleChange,
            value: getValue('fadeInRequired').value,
            valid: getValue('fadeInRequired').valid,
            placeholder: 'Image fadeInRequired',
            readOnly: false,
          }}
          question="fadeInRequired"
          type="switch"
          validateOn="change"
        />
      )}

      {sectionKeys.includes('fadeOutRequired') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'fadeOutRequired',
            onChange: handleChange,
            value: getValue('fadeOutRequired').value,
            valid: getValue('fadeOutRequired').valid,
            placeholder: 'Image fadeOutRequired',
            readOnly: false,
          }}
          question="fadeOutRequired"
          type="switch"
          validateOn="change"
        />
      )}

      {sectionKeys.includes('duration') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'duration',
            onChange: handleChange,
            value: getValue('duration').value,
            valid: getValue('duration').valid,
            placeholder: 'Time in seconds',
          }}
          question="duration"
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Only numbers allowed',
              validator: 'pattern',
              regex: /^[0-9]*(\.[0-9]*)?$/,
            },
          ]}
        />
      )}

      {sectionKeys.includes('colour') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'colour',
            onChange: handleChange,
            value: getValue('colour').value,
            valid: getValue('colour').valid,
            placeholder: 'Valid colour',
          }}
          question="colour"
          type="text"
          validateOn="change"
        />
      )}

      {sectionKeys.includes('enableAutoSize') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'enableAutoSize',
            onChange: handleChange,
            value: getValue('enableAutoSize').value,
            valid: getValue('enableAutoSize').valid,
            placeholder: 'Enable Auto Size',
            readOnly: false,
          }}
          question="enableAutoSize"
          type="switch"
          validateOn="change"
        />
      )}

      {sectionKeys.includes('textShadow') && (
        <FormControl
          dataEl={`${dataEl}-control`}
          inputProps={{
            dataEl,
            id: 'textShadow',
            onChange: handleChange,
            value: getValue('textShadow').value,
            valid: getValue('textShadow').valid,
            placeholder: 'Valid text shadow',
          }}
          question="textShadow"
          type="text"
          validateOn="change"
        />
      )}
    </Styled.Wrapper>
  );
};

ThemeSectionBrief.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Section. */
  section: PropTypes.string,
};

ThemeSectionBrief.defaultProps = {
  dataEl: 'ThemeSectionBrief',
  section: 'background',
};
