import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@team-seenit/atoms';
import * as Styled from './LineItem.styled';

export const LineItem = ({ title, text }) => (
  <Styled.LineWrapper>
    <Styled.TitleWrapper>
      <Typography color="accent" pre variant="bodyBold">
        {title}
        {': '}
      </Typography>
    </Styled.TitleWrapper>
    <Styled.BodyWrapper>
      <Typography color="black" lines={2} variant="body">
        {text}
      </Typography>
    </Styled.BodyWrapper>
  </Styled.LineWrapper>
);

LineItem.propTypes = {
  /** Text title for the component */
  title: PropTypes.string.isRequired,
  /** Text of component */
  text: PropTypes.string.isRequired,
};
