export enum OrgTypeEnum {
  AllOrganisations = 'allOrganisations',
  DefaultBrandGroups = 'defaultBrandGroups',
  OrganisationBrandGroups = 'organisationBrandGroups',
}

export enum AeAssetEnum {
  LowerThird = 'lowerThird',
  TitleCard = 'titleCard',
}
