export const destructureError = error => {
  if (!error) return [];

  const serviceErrors = error.graphQLErrors.map(
    ({
      extensions: {
        exception: {
          captureStackTrace: { data: { error: errorDetails } = {} } = {},
        } = {},
      } = {},
    }) => errorDetails
  );

  return serviceErrors[0]
    ? serviceErrors[0].split(',')
    : ['GQL validation errors'];
};
