import { gql } from '@apollo/client';

export const QuickEditThemeDetails = gql`
  fragment QuickEditThemeDetails on QuickEditTheme {
    __typename
    quickEditThemeId
    name
    orgId
    background {
      quickEditMedia {
        quickEditMediaId
      }
      colour
      duration
      fadeIn
      fadeInRequired
      fadeOutRequired
      height
      positionX
      positionY
      width
      enableAutoSize
    }
    textLine1 {
      quickEditMedia {
        quickEditMediaId
      }
      fontSize
      colour
      height
      positionX
      positionY
      positionYWhenNoLine2
      width
      textShadow
    }
    textLine2 {
      quickEditMedia {
        quickEditMediaId
      }
      fontSize
      colour
      height
      positionX
      positionY
      width
      textShadow
    }
    watermark {
      quickEditMedia {
        quickEditMediaId
      }
      colour
      height
      positionX
      positionY
      width
    }
    openingMedia {
      quickEditMediaId
    }
    closingMedia {
      quickEditMediaId
    }
    audioTracks {
      quickEditMediaId
    }
  }
`;
