import { gql } from '@apollo/client';
import { TierDetails } from '../../fragments';

export const GET_REGISTERED_FEATURES_QUERY = gql`
  query TTAdminGetAllTiers {
    seenit {
      admin {
        registeredFeatures {
          name
          description
          docType
          createdAt
          docType
          registeredFeatureId
          updatedAt
          valueIndicator
        }
      }
    }
  }
`;

export const CREATE_TIER_MUTATION = gql`
  mutation AdminCreateTier($createTier: CreateTierInput!) {
    seenit {
      createTier(createTier: $createTier) {
        ...TierDetails
      }
    }
  }

  ${TierDetails}
`;

export const UPDATE_TIER_MUTATION = gql`
  mutation AdminUpdateTier($tierId: ID!, $updateTier: UpdateTierInput!) {
    seenit {
      updateTier(tierId: $tierId, updateTier: $updateTier) {
        ...TierDetails
      }
    }
  }

  ${TierDetails}
`;

export const DELETE_TIER_MUTATION = gql`
  mutation AdminDeleteTier($tierId: ID!) {
    seenit {
      deleteTier(tierId: $tierId) {
        success
      }
    }
  }
`;

export const GET_TIER_BY_ID_QUERY = gql`
  query AdminGetTierById($tierId: ID!) {
    seenit {
      admin {
        tier(tierId: $tierId) {
          ...TierDetails
        }
      }
    }
  }

  ${TierDetails}
`;

export const GET_TIER_BY_ID_AND_ORGANISATION_QUERY = gql`
  query AdminGetTierByIdAndOrganisation($tierId: ID!) {
    seenit {
      admin {
        tier(tierId: $tierId) {
          ...TierDetails
          organisations {
            orgId
            name
            nickname
          }
          availableOrganisations {
            orgId
            name
            nickname
          }
        }
      }
    }
  }

  ${TierDetails}
`;

export const UPDATE_ORGANISATION_MUTATION = gql`
  mutation AdminUpdateOrganisationMutation(
    $orgId: ID!
    $organisationDetails: UpdateOrganisationInput!
  ) {
    seenit {
      adminUpdateOrganisation(
        orgId: $orgId
        organisationDetails: $organisationDetails
      ) {
        orgId
        name
        nickname
      }
    }
  }
`;

export const destructureDataForRegisteredFeatures = ({
  seenit: { admin: { registeredFeatures = [] } = {} } = {},
} = {}) => ({
  registeredFeatures,
});
