import React from 'react';

import { Typography } from '@team-seenit/atoms';

export const FormError = ({
  errors,
  section,
  field,
}: {
  errors: object;
  section?: string;
  field: string;
}) => {
  let error;
  if (section) error = errors?.[section]?.[field];
  else error = errors?.[field];

  if (error) return <Typography color="red">{error.message}</Typography>;
};
