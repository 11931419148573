import { gql } from '@apollo/client';

export const RegisteredFeatureDetails = gql`
  fragment RegisteredFeatureDetails on RegisteredFeature {
    __typename
    name
    description
    docType
    createdAt
    docType
    registeredFeatureId
    updatedAt
    valueIndicator
    createdBy
  }
`;
