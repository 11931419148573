export const defaultFormProps = {
  name: { value: undefined, valid: true, required: true },
  description: { value: undefined, valid: true, required: false },
  valueIndicator: { value: undefined, valid: true, required: true },
};

export const prepDefaultFormProps = ({ name, description, valueIndicator }) => {
  return {
    name: { value: name, valid: true, required: true },
    description: { value: description, valid: true, required: false },
    valueIndicator: { value: valueIndicator, valid: true, required: true },
  };
};
