/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { AUTH } from '@team-seenit/constants';
import { Button, TextInput, Toast } from '@team-seenit/atoms';
import { CloudinaryUploader } from '@team-seenit/organisms';
import { useAuth, useTheme } from '@team-seenit/contexts';
import { Controller, useForm } from 'react-hook-form';
import { createPortal } from 'react-dom';
import { UploadItem } from '@team-seenit/types';
import * as Styled from '../MediaUploadOrganism.styled';
import { mutations } from '../MediaUploadOrganism.gql';
import { MediaUploadFormProps } from './MediaUploadForm.types';

const { USER_ID_KEY } = AUTH;

export const MediaUploadForm = ({
  dataEl = 'MediaConfig',
  currentTab,
  orgId,
  setRefetchMedia,
}: MediaUploadFormProps) => {
  const { isDesktop } = useTheme();
  const { user: { [USER_ID_KEY]: userId } = {} } = useAuth();
  const toastRef = useRef();

  const rootElement = document.getElementById('root');

  const showToast = ((toastRef.current || {}) as {
    showToast: (message: string) => void;
  })?.showToast;

  const [
    createEditorMedium,
    { data: createdMedium, loading: creatingMedium },
  ] = useMutation(mutations.createEditorMedium, {
    onCompleted: () => showToast('Media uploaded successfully'),
  });

  interface FormValues {
    uploadItems: Partial<UploadItem>[];
    uploadTitle: string;
  }

  const { control, handleSubmit, watch, setValue } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { uploadItems: [], uploadTitle: '' },
  });

  const uploadItems = watch('uploadItems');
  const itemTitle = watch('uploadTitle');

  const handleChange = (id, data) => {
    setValue(
      'uploadItems',
      uploadItems.map(currUploadItem => {
        if (currUploadItem.id === id) return { ...currUploadItem, ...data };
        return currUploadItem;
      }),
      {
        shouldValidate: true,
      }
    );
  };

  const preset = 'intro_video';
  const fontsPreset = 'fonts_authenticated_raw';

  const handleDelete = id => {
    setValue(
      'uploadItems',
      uploadItems.filter(currUploadItem => currUploadItem.id !== id),
      {
        shouldValidate: Boolean(uploadItems.length),
      }
    );
  };

  const handleNewUpload = (id, data) => {
    setValue('uploadItems', [...uploadItems, { id, ...data }]);
  };

  useEffect(() => {
    if (createdMedium) {
      setValue('uploadItems', [], { shouldValidate: true });
      setValue('uploadTitle', '', { shouldValidate: true });
      setRefetchMedia(true);
    }
  }, [createdMedium]);

  const mutationDataItem = item => ({
    accessControl: item.accessControl,
    accessMode: item.accessMode,
    approvedBy: userId,
    deliveryType: item.deliveryType,
    duration: item.duration,
    eager: item.eager,
    fileFormat: currentTab === 'fonts' ? 'OTF' : item.fileFormat,
    isAudio: item.isAudio,
    mediaFormat: currentTab === 'fonts' ? 'video' : item.mediaFormat,
    mediaType: currentTab,
    orgId,
    publicId: item.publicId,
    thumbnailUrl:
      item.mediaFormat === 'video'
        ? `${item.uploadUrl.match(/(.+)\./)[1]}.jpg`
        : item.uploadUrl,
    title: itemTitle,
    uploadPreset: `${currentTab === 'fonts' ? fontsPreset : preset}`,
    uploadUrl: item.uploadUrl,
  });

  const onClick = () => {
    uploadItems.forEach(item => {
      createEditorMedium({
        variables: {
          data: mutationDataItem(item),
        },
      });
    });
  };

  const uploadsComplete =
    Array.isArray(uploadItems) &&
    uploadItems.length &&
    uploadItems.every(({ status }) => status === 200);

  const mediaTypeForUploader = () => {
    if (currentTab === 'audioTracks') return 'audio';
    if (currentTab === 'fonts') return 'font';
    return ['openingMedia', 'closingMedia'].includes(currentTab)
      ? 'video'
      : 'image';
  };

  return (
    <form onSubmit={handleSubmit(uploadsComplete ? onClick : undefined)}>
      <Styled.Wrapper dataEl={dataEl}>
        <Styled.TabSideWrapper>
          <Controller
            name="uploadItems"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CloudinaryUploader
                {...field}
                columns={isDesktop ? 3 : 2}
                folder={`${orgId}/${currentTab}`}
                multipleUploads={false}
                mediaType={mediaTypeForUploader()}
                onChange={handleChange}
                onDelete={handleDelete}
                onNewUpload={handleNewUpload}
                preset={`${currentTab === 'fonts' ? fontsPreset : preset}`}
                showUploadCaptions
                uploadItems={uploadItems}
              />
            )}
          />
          <Controller
            name="uploadTitle"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextInput
                dataEl={dataEl}
                placeholder="Item title..."
                {...field}
              />
            )}
          />

          <Button
            animation={uploadsComplete ? 'pulse' : undefined}
            dataEl="MediaConfigSubmitButton"
            disabled={!uploadsComplete || creatingMedium || !itemTitle}
            id="openingMediaSubmit"
            inactive={!uploadsComplete}
            large
            type="submit"
            width="100%"
          >
            Submit
          </Button>
          {!!rootElement &&
            createPortal(
              <Toast
                dataEl={`${dataEl}Toast`}
                autoClose="3000"
                containerId={`${dataEl}ToastContainer`}
                position="top-center"
                ref={toastRef}
                toastId={`${dataEl}Toast`}
              />,
              rootElement
            )}
        </Styled.TabSideWrapper>
      </Styled.Wrapper>
    </form>
  );
};
