import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';

import { Button, Card, Grid } from '@team-seenit/atoms';

import { useQuery } from '@apollo/client';

import {
  GET_REGISTERED_FEATURES_QUERY,
  destructureDataForFeatures,
} from './Features.gql';
import * as Styled from './Features.styled';

import { LineItem } from '../../molecules';

export function Features({ dataEl }): JSX.Element {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleCardClick = registeredFeatureId => {
    push(`/tiers/features/${registeredFeatureId}/setup`);
  };

  const { data, error } = useQuery(GET_REGISTERED_FEATURES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <h1>GQL Error</h1>;

  const { registeredFeatures } = destructureDataForFeatures(data);

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Button onClick={() => push(`${pathname}/create`)}>
        Create a new feature
      </Button>

      <Grid>
        {registeredFeatures.map(feature => (
          <Card
            key={feature.registeredFeatureId}
            cardStyle="ghost"
            cardStyleHover="ghostHover"
            dataEl="Card"
            height="150px"
            onClick={() => handleCardClick(feature.registeredFeatureId)}
            padding="20px"
            type="card"
            width="400px"
          >
            <Styled.CardContentWrapper>
              <LineItem text={feature.registeredFeatureId} title="Id" />
              <LineItem text={feature.name} title="Name" />
              <LineItem text={feature.description} title="Description" />
              <LineItem text={feature.valueIndicator} title="ValueIndicator" />
            </Styled.CardContentWrapper>
          </Card>
        ))}
      </Grid>
    </Styled.Wrapper>
  );
}

Features.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
};

Features.defaultProps = {
  dataEl: 'Features',
};
