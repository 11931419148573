import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  width: '100%',
}));

export const TableWrapper = styled.div(({ theme: { palette: { grey2 } } }) => ({
  table: {
    width: '100%',
  },

  'td, th': {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '1px',
  },

  th: {
    backgroundColor: grey2,
  },

  'tr:nth-child(even)': {
    backgroundColor: '#dddddd',
  },
}));
