import { gql } from '@apollo/client';

export const mutations = {
  createEditorOrgAsset: gql`
    mutation CreateEditorOrgAsset($data: createEditorOrgAssetInput!) {
      seenit {
        createEditorOrgAsset(data: $data) {
          editorOrgAssetId
        }
      }
    }
  `,

  updateEditorOrgAsset: gql`
    mutation UpdateEditorOrgAsset(
      $editorOrgAssetId: ID!
      $data: UpdateEditorOrgAssetInput!
    ) {
      seenit {
        updateEditorOrgAsset(editorOrgAssetId: $editorOrgAssetId, data: $data) {
          editorOrgAssetId
        }
      }
    }
  `,

  deleteEditorOrgAsset: gql`
    mutation DeleteEditorOrgAsset($editorOrgAssetId: ID!) {
      seenit {
        deleteEditorOrgAsset(editorOrgAssetId: $editorOrgAssetId) {
          success
        }
      }
    }
  `,
};

export const queries = {
  getEditorOrgAssets: gql`
    query EditorOrgAssets($filter: EditorAdminGenericFilterInput) {
      seenit {
        editorOrgAssets(filter: $filter) {
          name
          value
          editorOrgAssetId
          category
        }
      }
    }
  `,

  getEditorOrgAsset: gql`
    query EditorOrgAsset($editorOrgAssetId: ID!) {
      seenit {
        editorOrgAsset(editorOrgAssetId: $editorOrgAssetId) {
          category
          name
          value
        }
      }
    }
  `,
};
