import React from 'react';

import PropTypes from 'prop-types';

import { Typography, UnorderedList } from '@team-seenit/atoms';

import * as Styled from './Error.styled';
import { destructureError } from './destructureError';

export const Error = ({ dataEl, rawErrors }) => {
  const errors = destructureError(rawErrors);

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <UnorderedList>
        {errors.map((error, index) => (
          <li key={`error-${index + 0}`}>
            <Typography color="red" variant="subtext">
              {error}
            </Typography>
          </li>
        ))}
      </UnorderedList>
    </Styled.Wrapper>
  );
};

Error.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Set GQL errors */
  rawErrors: PropTypes.objectOf(PropTypes.any),
};

Error.defaultProps = {
  dataEl: 'Error',
  rawErrors: undefined,
};
