export const defaultFormProps = {
  name: { value: undefined, valid: true },
  background: {
    quickEditMedia: { value: undefined, valid: true },
    colour: { value: undefined, valid: true },
    duration: { value: undefined, valid: true },
    fadeIn: { value: undefined, valid: true },
    fadeInRequired: { value: false, valid: true },
    fadeOutRequired: { value: false, valid: true },
    height: { value: undefined, valid: true },
    positionX: { value: undefined, valid: true },
    positionY: { value: undefined, valid: true },
    width: { value: undefined, valid: true },
    enableAutoSize: { value: false, valid: true },
  },
  textLine1: {
    quickEditMedia: { value: undefined, valid: true },
    fontSize: { value: undefined, valid: true },
    colour: { value: undefined, valid: true },
    positionX: { value: undefined, valid: true },
    positionY: { value: undefined, valid: true },
    positionYWhenNoLine2: { value: undefined, valid: true },
    textShadow: { value: undefined, valid: true },
  },
  textLine2: {
    quickEditMedia: { value: undefined, valid: true },
    fontSize: { value: undefined, valid: true },
    colour: { value: undefined, valid: true },
    positionX: { value: undefined, valid: true },
    positionY: { value: undefined, valid: true },
    textShadow: { value: undefined, valid: true },
  },
  watermark: {
    quickEditMedia: { value: undefined, valid: true },
    colour: { value: undefined, valid: true },
    height: { value: undefined, valid: true },
    positionX: { value: undefined, valid: true },
    positionY: { value: undefined, valid: true },
    width: { value: undefined, valid: true },
  },
  openingMedia: { value: undefined, valid: true },
  closingMedia: { value: undefined, valid: true },
  audioTracks: { value: undefined, valid: true },
  selections: {
    openingMedia: { value: [], valid: true },
    closingMedia: { value: [], valid: true },
    audioTracks: { value: [], valid: true },
  },
};
