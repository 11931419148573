import React, { useState, useEffect } from 'react';
import { useReactiveVar, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { ActionCard, Button, Pill, HR } from '@team-seenit/atoms';
import { StatsCard } from '@team-seenit/molecules';

import {
  quickEditThemeIdVar,
  quickEditThemesReloadVar,
  quickEditOrgStatsDataVar,
} from '../variables';

import * as Styled from './ThemesCurrentState.styled';

import {
  DELETE_QUICK_EDIT_THEME_MUTATION,
  GET_ORGANISATIONS_THEMES_QUERY,
  destructureDataForOrgThemes,
} from './ThemesCurrentState.gql';

export const ThemesCurrentState = ({ dataEl, orgId }) => {
  const [deleteQuickEditThemeId, setDeleteQuickEditThemeId] = useState();

  const [fetchOrgThemes, { data: getOrgThemesData, error }] = useLazyQuery(
    GET_ORGANISATIONS_THEMES_QUERY
  );
  const [deleteQuickEditTheme, { data: deletedTheme }] = useMutation(
    DELETE_QUICK_EDIT_THEME_MUTATION
  );

  const quickEditThemesReload = useReactiveVar(quickEditThemesReloadVar);
  const quickEditOrgStatsData = useReactiveVar(quickEditOrgStatsDataVar);
  const currentQuickEditThemeId = useReactiveVar(quickEditThemeIdVar);

  const { seenit: { organisation: { tier: { name } = {} } = {} } = {} } =
    quickEditOrgStatsData || {};

  const resetForm = () => {
    quickEditThemeIdVar(undefined);
  };

  useEffect(() => {
    if (orgId && quickEditThemesReload) {
      fetchOrgThemes({ variables: { orgId } });
    }
  }, [orgId, quickEditThemesReload]);

  useEffect(() => {
    if (deleteQuickEditThemeId) {
      deleteQuickEditTheme({
        variables: { quickEditThemeId: deleteQuickEditThemeId },
      });

      setDeleteQuickEditThemeId(undefined);
    }
  }, [deleteQuickEditThemeId]);

  useEffect(() => {
    if (deletedTheme) {
      resetForm();
      quickEditThemesReloadVar('Deleted');
    }
  }, [deletedTheme]);

  if (error) return <h1>GQL Error</h1>;

  const { icon, title, stats, color, items } = destructureDataForOrgThemes(
    getOrgThemesData
  );

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Styled.TierWrapper>
        <Pill color="accent" readOnly text={`Current Tier: ${name}`} />
      </Styled.TierWrapper>

      <StatsCard color={color} icon={icon} stats={stats} title={title} />

      <Button onClick={resetForm}>Reset form / Create theme</Button>

      {items.map(item => {
        return (
          <Styled.Empty key={item.quickEditThemeId}>
            <Styled.MediaWrapper
              key={item.quickEditThemeId}
              id={item.quickEditThemeId}
            >
              <Button
                icon="cut"
                onClick={() => setDeleteQuickEditThemeId(item.quickEditThemeId)}
                variant="outlineDanger"
              >
                Delete
              </Button>
              <Styled.ImageDisplay>
                <ActionCard
                  cardStyle="ghost"
                  cardStyleHover="ghostHover"
                  disabled={currentQuickEditThemeId === item.quickEditThemeId}
                  flexGrow={1}
                  height="auto"
                  icon="edit"
                  iconSize="massive"
                  mainText={item.name}
                  mainTextVariant="subheader"
                  onClick={() => quickEditThemeIdVar(item.quickEditThemeId)}
                  subText="Click to edit"
                  subTextVariant="bodyBold"
                />
              </Styled.ImageDisplay>
            </Styled.MediaWrapper>
            <Styled.HRWrapper>
              <HR color="accent" />
            </Styled.HRWrapper>
          </Styled.Empty>
        );
      })}
    </Styled.Wrapper>
  );
};

ThemesCurrentState.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Org id. */
  orgId: PropTypes.string.isRequired,
};

ThemesCurrentState.defaultProps = {
  dataEl: 'ThemesCurrentState',
};
