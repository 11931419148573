import { gql } from '@apollo/client';

export const GET_TOGGLE_QUERY = gql`
  query AdminGetAllToggles {
    seenit {
      admin {
        toggles {
          docType
          toggleId
          name
          description
        }
      }
    }
  }
`;

export const CREATE_TOGGLE_MUTATION = gql`
  mutation AdminCreateToggleMutation($toggleDetails: CreateToggleInput!) {
    seenit {
      createToggle(toggleDetails: $toggleDetails) {
        toggleId
        name
        description
        status
      }
    }
  }
`;

export const destructureDataForToggles = ({
  seenit: { admin: { toggles = [] } = {} } = {},
} = {}) => toggles;
