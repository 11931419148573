import React from 'react';
import { PATHS } from '@team-seenit/constants';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { capitalize } from 'lodash';

import {
  AnalyticsProvider,
  AuthProvider,
  defaultTheme,
  ThemeProvider,
  CloudinaryProvider,
} from '@team-seenit/contexts';

import App from './App';

import { contentfulSetup, envConfig } from './config';

const config = envConfig();

contentfulSetup(config.contentfulConfig);
const history = createBrowserHistory();

Sentry.init({
  allowUrls: [
    /https:\/\/(app.seenit.io|ui-(master|demo|staging).seenit.studio|dev.seenit.studio|stellar-ui.eu.ngrok.io)/,
  ],
  dsn: config.sentry.dsn,
  environment: capitalize(config.sentry.environment),
  integrations: [
    new TracingIntegrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      tracingOrigins: [config.graphqlEndpoint],
    }),
  ],
  tracesSampleRate: 0.2,
});

const Entry = () => {
  return (
    <Router history={history}>
      <AnalyticsProvider>
        <AuthProvider
          audience={config.auth0Config.audience}
          clientId={config.auth0Config.clientId}
          domain={config.auth0Config.domain}
          redirectUri={`${window.location.origin}${PATHS.LOGIN_CALLBACK}`}
          value={{ config }}
        >
          <ThemeProvider theme={defaultTheme}>
            <CloudinaryProvider config={{ ...config.cloudinaryConfig }}>
              <App />
            </CloudinaryProvider>
          </ThemeProvider>
        </AuthProvider>
      </AnalyticsProvider>
    </Router>
  );
};

export default Entry;
