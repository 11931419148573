import { defaultFormProps } from './defaultFormProps';

const prepForFE = ({ props, name, value }) => {
  return { [name]: { ...props[name], value, initialValue: value } };
};

const prepSectionForFe = ({ sectionName, section = {} }) => {
  return Object.keys(defaultFormProps[sectionName]).reduce((prev, key) => {
    const { [key]: value } = section;
    return {
      ...prev,
      ...prepForFE({
        props: defaultFormProps[sectionName],
        name: key,
        value: value || defaultFormProps[sectionName][key].value,
      }),
    };
  }, {});
};

const prepareSectionForMedia = (quickEditMediaData = {}) => {
  if (!quickEditMediaData) return {};
  const { quickEditMedia } = quickEditMediaData;

  if (!quickEditMedia) return quickEditMediaData;

  const { quickEditMedia: { quickEditMediaId } = {} } = quickEditMediaData;

  return {
    ...quickEditMediaData,
    quickEditMedia: quickEditMediaId,
  };
};

const prepSubSection = (media = []) => {
  if (!media) return [];

  return media.map(item => item.quickEditMediaId);
};

export const prepopulateBrief = (data = {}) => {
  const {
    seenit: {
      quickEditTheme: {
        name,
        background = {},
        textLine1 = {},
        textLine2 = {},
        watermark = {},
        openingMedia = [],
        closingMedia = [],
        audioTracks = [],
      } = {},
    } = {},
  } = data;

  return {
    ...defaultFormProps,
    ...prepForFE({ props: defaultFormProps, name: 'name', value: name }),
    background: {
      ...prepSectionForFe({
        sectionName: 'background',
        section: { ...prepareSectionForMedia(background) },
      }),
    },
    textLine1: {
      ...prepSectionForFe({
        sectionName: 'textLine1',
        section: { ...prepareSectionForMedia(textLine1) },
      }),
    },
    textLine2: {
      ...prepSectionForFe({
        sectionName: 'textLine2',
        section: { ...prepareSectionForMedia(textLine2) },
      }),
    },
    watermark: {
      ...prepSectionForFe({
        sectionName: 'watermark',
        section: { ...prepareSectionForMedia(watermark) },
      }),
    },
    selections: {
      openingMedia: { value: prepSubSection(openingMedia), valid: true },
      closingMedia: { value: prepSubSection(closingMedia), valid: true },
      audioTracks: { value: prepSubSection(audioTracks), valid: true },
    },
  };
};

export const prepopulateBriefAfterUpdate = (data = {}) => {
  const { seenit: { updateQuickEditTheme } = {} } = data;
  const newData = {
    seenit: {
      quickEditTheme: {
        ...updateQuickEditTheme,
      },
    },
  };

  return prepopulateBrief(newData);
};
