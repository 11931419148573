import { gql } from '@apollo/client';
import { TierDetails } from '../../fragments';

export const GET_ORGANISATION_QUERY = gql`
  query GetOrganisation($orgId: ID!) {
    seenit {
      organisation(orgId: $orgId) {
        orgId
        name
        nickname
        addons {
          featureAddonId
          featureValue {
            featureValueId
            registeredFeature {
              docType
              registeredFeatureId
              name
              description
              valueIndicator
              createdBy
            }
            value
          }
          startDate
          endDate
        }
        tier {
          ...TierDetails
        }
      }
    }
  }

  ${TierDetails}
`;

export const GET_REGISTERED_FEATURES_QUERY = gql`
  query AdminGetAllOrganisations {
    seenit {
      admin {
        registeredFeatures {
          name
          description
          docType
          createdAt
          docType
          registeredFeatureId
          updatedAt
          valueIndicator
        }
      }
    }
  }
`;

export const CREATE_TIER_MUTATION = gql`
  mutation AdminCreateTier($createTier: CreateTierInput!) {
    seenit {
      createTier(createTier: $createTier) {
        ...TierDetails
      }
    }
  }

  ${TierDetails}
`;

export const UPDATE_TIER_MUTATION = gql`
  mutation AdminUpdateTier($tierId: ID!, $updateTier: UpdateTierInput!) {
    seenit {
      updateTier(tierId: $tierId, updateTier: $updateTier) {
        ...TierDetails
      }
    }
  }

  ${TierDetails}
`;

export const DELETE_TIER_MUTATION = gql`
  mutation AdminDeleteTier($tierId: ID!) {
    seenit {
      deleteTier(tierId: $tierId) {
        success
      }
    }
  }
`;

export const GET_TIER_BY_ID_QUERY = gql`
  query AdminGetTierById($tierId: ID!) {
    seenit {
      admin {
        tier(tierId: $tierId) {
          ...TierDetails
          organisations {
            orgId
            name
            nickname
          }
          availableOrganisations {
            orgId
            name
            nickname
          }
        }
      }
    }
  }

  ${TierDetails}
`;

export const UPDATE_ORGANISATION_MUTATION = gql`
  mutation AdminUpdateOrganisationMutation(
    $orgId: ID!
    $organisationDetails: UpdateOrganisationInput!
  ) {
    seenit {
      adminUpdateOrganisation(
        orgId: $orgId
        organisationDetails: $organisationDetails
      ) {
        orgId
        name
        addons {
          featureAddonId
        }
      }
    }
  }
`;

export const destructureDataForRegisteredFeatures = ({
  seenit: { admin: { registeredFeatures = [] } = {} } = {},
} = {}) => ({
  registeredFeatures,
});

export const destructureDataForOrganisation = ({
  seenit: { organisation } = {},
} = {}) => ({
  organisation,
});

const addonStartedRunning = (feat, orgAddons) => {
  if (!orgAddons || !orgAddons.length) return undefined;

  const foundRunning = orgAddons
    .filter(Boolean)
    .find(
      addon =>
        addon.featureValue.registeredFeature.registeredFeatureId ===
          feat.registeredFeatureId && addon.endDate > Date.now()
    );
  return foundRunning;
};

export const availableAddons = (registeredFeatures = [], organisation = {}) => {
  const {
    tier: { registeredFeatures: tierFeatures } = {},
    addons: orgAddons = [],
  } = organisation;

  const activeAddons = (tierFeatures || []).filter(
    addon => addon.endDate > Date.now()
  );

  const featuresNotInTier = registeredFeatures
    .filter(
      feat =>
        !activeAddons.find(
          tierFeat =>
            tierFeat.registeredFeature.registeredFeatureId ===
            feat.registeredFeatureId
        )
    )
    .filter(Boolean);

  return featuresNotInTier.map(feat => {
    const addonRunning = addonStartedRunning(feat, orgAddons);
    const addonStarted = addonRunning && addonRunning.startDate <= Date.now();

    const enableMessages = (methodAddonRunning, methodAddonStarted) => {
      if (methodAddonStarted)
        return 'Cannot be disabled because the addon is active';
      if (methodAddonRunning)
        return 'This addon is not active, turning it off will remove it';

      return undefined;
    };

    return {
      ...feat,
      ...addonRunning,
      type: 'addon',
      enableReadonly: addonStarted,
      enableReadonlyMessage: enableMessages(addonRunning, addonStarted),
      startDateReadonly: addonStarted,
      startDateReadonlyMessage: addonStarted
        ? 'This addon has started, you cannot change the start date'
        : undefined,
    };
  });
};
