import React, { useState } from 'react';
import { useParams } from 'react-router';

import { CreateFeature } from './CreateFeature';
import { EditFeature } from './EditFeature';

import { defaultFormProps } from './helpers';

export function FeatureBrief(): JSX.Element {
  const { registeredFeatureId } = useParams();

  const [submitted, setSubmitted] = useState<boolean | false>();
  const [userData, setUserData] = useState(defaultFormProps);

  const handleFormChanges = (value, { id, valid }) => {
    setUserData({ ...userData, [id]: { ...userData[id], value, valid } });
  };

  const getValue = formKey => {
    return userData[formKey];
  };

  const isformValid = () => {
    return Object.keys(userData).every(
      formKey =>
        (userData[formKey].required &&
          !!userData[formKey].value &&
          userData[formKey].valid) ||
        (!userData[formKey].required && userData[formKey].valid)
    );
  };

  if (!registeredFeatureId)
    return (
      <CreateFeature
        getValue={getValue}
        handleFormChanges={handleFormChanges}
        isformValid={isformValid}
        setSubmitted={setSubmitted}
        submitted={submitted}
        userData={userData}
      />
    );

  return (
    <EditFeature
      getValue={getValue}
      handleFormChanges={handleFormChanges}
      isformValid={isformValid}
      registeredFeatureId={registeredFeatureId}
      setSubmitted={setSubmitted}
      setUserData={setUserData}
      submitted={submitted}
      userData={userData}
    />
  );
}
