const addAddon = (addon, prefix, feature) => {
  if (!addon) return {};

  return {
    [`${prefix}${feature.registeredFeatureId}-startdate`]: {
      // value: true,
      valid: true,
      required: false,
    },
    [`${prefix}${feature.registeredFeatureId}-enddate`]: {
      // value: true,
      valid: true,
      required: false,
    },
  };
};

export const prepFeaturesForEdit = ({ features, prefix, addon }) => {
  return {
    ...((!!features &&
      features.reduce((previous, feature) => {
        return {
          ...previous,
          [`${prefix}${feature.registeredFeatureId}-value`]: {
            value: feature.valueIndicator === 'boolean' ? false : undefined,
            valid: true,
            required: false,
          },
          [`${prefix}${feature.registeredFeatureId}-enable`]: {
            value: false,
            valid: true,
            required: false,
          },
          ...addAddon(addon, prefix, feature),
        };
      }, {})) ||
      {}),
  };
};
