import styled from 'styled-components';

export const ListItem = styled.li`
  display: flex;
  gap: 10px;
  width: max-content;
`;

export const Wrapper = styled.div`
  margin-top: 20px;
`;
