import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
}));

export const CardContentWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LineItem = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '4px 0 4px 0',
  alignItems: 'center',
  columnGap: '20px',
}));

export const Buttons = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '4px 0 4px 0',
  alignItems: 'center',
  columnGap: '20px',
}));
