import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginTop: '20px',
}));

export const InputsWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const TierWrapper = styled.div(() => ({
  marginBottom: '20px',
}));

export const HRWrapper = styled.div(() => ({
  marginTop: '20px',
}));

export const TabSideWrapper = styled.div(() => ({
  flex: 1,
}));

export const InputWrapper = styled.div(() => ({}));

export const ColorPaletteWrapper = styled.div(() => ({
  gap: '10px',
  display: 'flex',
  flexDirection: 'column',
}));
