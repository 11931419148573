import React, { useState } from 'react';
import { MediaUploadForm } from './Form';
import { MediaUploadCurrentState } from './CurrentState';
import { MediaUploadOrganismProps } from './MediaUploadOrganism.types';
import * as Styled from './MediaUploadOrganism.styled';

export const MediaUploadOrganism = ({
  currentTab,
  currentOrgId,
  currentBrandId,
  orgType,
}: MediaUploadOrganismProps) => {
  const [refetchMedia, setRefetchMedia] = useState(false);

  return (
    <>
      <Styled.TabSideWrapper>
        <MediaUploadForm
          setRefetchMedia={setRefetchMedia}
          currentTab={currentTab}
          orgId={currentOrgId}
        />
      </Styled.TabSideWrapper>
      <Styled.TabSideWrapper>
        <MediaUploadCurrentState
          orgType={orgType}
          setRefetchMedia={setRefetchMedia}
          refetchMedia={refetchMedia}
          currentTab={currentTab}
          orgId={currentOrgId}
          brandId={currentBrandId}
        />
      </Styled.TabSideWrapper>
    </>
  );
};
