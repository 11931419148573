import { gql } from '@apollo/client';

export const GET_ORGANISATIONS_THEMES_QUERY = gql`
  query ThemesCurrentState($orgId: ID!) {
    seenit {
      quickEditThemes(orgId: $orgId) {
        quickEditThemeId
        name
      }
    }
  }
`;

export const DELETE_QUICK_EDIT_THEME_MUTATION = gql`
  mutation DeleteQuickEditTheme($quickEditThemeId: ID!) {
    seenit {
      deleteQuickEditTheme(id: $quickEditThemeId) {
        success
      }
    }
  }
`;

export const destructureDataForOrgStats = (data = {}, currentTab) => {
  const {
    seenit: {
      organisation: { tier: { name } = {}, quickEditsConfig = {} } = {},
    } = {},
  } = data;
  const { [currentTab]: responseTab = [] } = quickEditsConfig;

  return {
    name,
    icon: 'brand_editor',
    title: 'Total',
    stats: responseTab.length || 0,
    color: 'blue',
    items: responseTab || [],
  };
};

export const destructureDataForOrgThemes = (data = {}) => {
  const { seenit: { quickEditThemes = [] } = {} } = data;

  return {
    name: 'test',
    icon: 'brand_editor',
    title: 'Total',
    stats: quickEditThemes.length || 0,
    color: 'blue',
    items: quickEditThemes || [],
  };
};
