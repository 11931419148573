import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';

import { CREATE_TIER_MUTATION } from './Brief.gql';
import { TierBriefPresenter } from './TierBriefPresenter';
import { prepDefaultFormProps } from './helpers';

export function CreateTier({
  dataEl,
  getValue,
  handleFormChanges,
  isformValid,
  setSubmitted,
  submitted,
  setUserData,
  userData,
  registeredFeatures,
}: TiersOrgProps): JSX.Element {
  const { push } = useHistory();
  const [
    createTier,
    { data: createTierData, error: createTierError },
  ] = useMutation(CREATE_TIER_MUTATION);

  useEffect(() => {
    if (registeredFeatures)
      setUserData({
        ...prepDefaultFormProps({
          registeredFeatureValues: registeredFeatures,
        }),
      });
  }, [registeredFeatures]);

  useEffect(() => {
    const { seenit: { createTier: createdTier } = {} } = createTierData || {};

    if (createdTier?.tierId) {
      setSubmitted(false);
      push(`/tiers`);
    }
  }, [createTierData]);

  useEffect(() => {
    if (createTierError) {
      setSubmitted(false);
    }
  }, [createTierError]);

  const buildFeatureValue = () => {
    return Object.keys(userData)
      .map(key => {
        if (key.startsWith('feature-') && key.endsWith('-enable')) {
          const { value } = userData[key];
          if (value === true) {
            const registeredFeatureId = key
              .replace('feature-', '')
              .replace('-enable', '');

            const { value: selectedValue } = userData[
              `feature-${registeredFeatureId}-value`
            ];

            return { registeredFeatureId, value: selectedValue.toString() };
          }
        }
        return null;
      })
      .filter(Boolean);
  };

  const handleFormSubmission = () => {
    setSubmitted(true);
    createTier({
      variables: {
        createTier: {
          description: userData.description.value,
          name: userData.name.value,
          registeredFeatureValues: buildFeatureValue(),
        },
      },
    });
  };

  return (
    <TierBriefPresenter
      buttonTitle="Create tier"
      dataEl={dataEl}
      errors={createTierError}
      getValue={getValue}
      handleFormChanges={handleFormChanges}
      handleFormSubmission={handleFormSubmission}
      isformValid={isformValid}
      registeredFeatures={registeredFeatures}
      submitted={submitted}
      title="Creating a new tier"
    />
  );
}

CreateTier.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
};

CreateTier.defaultProps = {
  dataEl: 'CreateTier',
};
