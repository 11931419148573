import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
}));

export const MediaWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));

export const VideoPlayer = styled.div(({ isAudio }) => ({
  height: isAudio ? '100px' : '375px',
  width: '100%',
  marginLeft: '10px',
}));

export const ImageDisplay = styled.div(() => ({
  height: '150px',
  width: '100%',
  marginLeft: '10px',
  backgroundColor: 'grey',
}));

export const TierWrapper = styled.div(() => ({
  marginBottom: '20px',
}));

export const HRWrapper = styled.div(() => ({
  marginTop: '20px',
}));

export const TitleWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const Empty = styled.div(() => ({}));
