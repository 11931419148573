export const defaultFormProps = {
  name: null,
  background: {
    editorMedia: null,
    colorByReference: null,
    colorByText: null,
    duration: null,
    fadeIn: null,
    fadeInRequired: false,
    fadeOut: null,
    fadeOutRequired: false,
    height: null,
    positionX: null,
    positionY: null,
    minWidth: null,
    widthCanGrow: false,
  },
  primaryText: {
    fontSize: null,
    colorByReference: null,
    colorByText: null,
    font: null,
    positionX: null,
    positionY: null,
    positonYWhenNoSecondaryText: null,
  },
  secondaryText: {
    colorByReference: null,
    colorByText: null,
    font: null,
    fontSize: null,
    positionX: null,
    positionY: null,
  },
};
