import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import { CreateTier } from './CreateTier';
import { EditTier } from './EditTier';
import { defaultFormProps } from './helpers';
import {
  GET_REGISTERED_FEATURES_QUERY,
  destructureDataForRegisteredFeatures,
} from './Brief.gql';

export function TierBrief(): JSX.Element {
  const { tierId } = useParams();

  const [submitted, setSubmitted] = useState<boolean | false>();
  const [userData, setUserData] = useState(defaultFormProps);

  const { loading, data: registeredFeaturesData, error } = useQuery(
    GET_REGISTERED_FEATURES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const { registeredFeatures } = destructureDataForRegisteredFeatures(
    registeredFeaturesData
  );

  const handleFormChanges = (value, { id, valid }) => {
    setUserData({ ...userData, [id]: { ...userData[id], value, valid } });
  };

  const getValue = formKey => {
    return userData[formKey];
  };

  const isformValid = () => {
    return Object.keys(userData).every(
      formKey =>
        (userData[formKey].required &&
          !!userData[formKey].value &&
          userData[formKey].valid) ||
        (!userData[formKey].required && userData[formKey].valid)
    );
  };

  if (loading) return 'loading features';
  if (error) return 'Error fetch Features';
  if (!registeredFeatures?.length) return 'Feature list is empty';

  if (!tierId)
    return (
      <CreateTier
        getValue={getValue}
        handleFormChanges={handleFormChanges}
        isformValid={isformValid}
        registeredFeatures={registeredFeatures}
        setSubmitted={setSubmitted}
        setUserData={setUserData}
        submitted={submitted}
        userData={userData}
      />
    );

  return (
    <EditTier
      getValue={getValue}
      handleFormChanges={handleFormChanges}
      isformValid={isformValid}
      registeredFeatures={registeredFeatures}
      setSubmitted={setSubmitted}
      setUserData={setUserData}
      submitted={submitted}
      tierId={tierId}
      userData={userData}
    />
  );
}
