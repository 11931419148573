import { ApolloLink } from '@apollo/client';

const createSessionLink = (logger, config) => {
  return new ApolloLink((operation, forward) => {
    const startTime = new Date().getTime();

    return forward(operation).map(result => {
      if (config.debug) {
        const { variables, operationName } = operation;
        const ellapsed = new Date().getTime() - startTime;
        const { data, errors = 'NONE' } = result;
        const type = ` ${operationName || 'NOT-SPECIFIED'}`;
        const info = {
          operation: {
            type,
            variables,
          },
          timing: `${ellapsed}ms`,
          result: data,
          errors,
        };

        if (logger) {
          logger.info({
            message: `GRAPHQL ${type}`,
            custom: JSON.stringify(info),
          });
        } else {
          console.groupCollapsed(`${type}`); // eslint-disable-line
          console.log(info); // eslint-disable-line
          console.groupEnd(); // eslint-disable-line
        }
      }

      return result;
    });
  });
};

export default createSessionLink;
