import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  GET_TIER_BY_ID_AND_ORGANISATION_QUERY,
  UPDATE_ORGANISATION_MUTATION,
} from './Brief.gql';

import { Organisations, LineItem } from '../../../molecules';

export function TierOrganisations(): JSX.Element {
  const { tierId } = useParams();
  const [tierCombined, setTierCombined] = useState({});

  const [getTierByIdAndOrg, { loading, data, error }] = useLazyQuery(
    GET_TIER_BY_ID_AND_ORGANISATION_QUERY
  );

  const [updateOrganisation, { data: updatedOrganisation }] = useMutation(
    UPDATE_ORGANISATION_MUTATION
  );

  const { seenit: { admin: { tier = {} } = {} } = {} } = data || {};

  useEffect(() => {
    if (!tier) return;

    if (tier) {
      setTierCombined({ ...tier });
    }
  }, [data]);

  useEffect(() => {
    if (tierId) {
      getTierByIdAndOrg({
        variables: { tierId },
      });
    }
  }, [tierId]);

  useEffect(() => {
    if (tierId && updatedOrganisation) {
      let organisations = tierCombined.organisations || [];
      let availableOrganisations = tierCombined.availableOrganisations || [];

      const {
        seenit: { adminUpdateOrganisation: updatedOrg } = {},
      } = updatedOrganisation;

      const orgInTier = organisations.find(
        org => org.orgId === updatedOrg.orgId
      );

      if (orgInTier) {
        organisations = organisations.filter(
          org => org.orgId !== orgInTier.orgId
        );

        availableOrganisations = [...availableOrganisations, { ...updatedOrg }];
      } else {
        availableOrganisations = availableOrganisations.filter(
          org => org.orgId !== updatedOrg.orgId
        );

        organisations = [...organisations, { ...updatedOrg }];
      }

      setTierCombined({
        ...tierCombined,
        organisations,
        availableOrganisations,
      });
    }
  }, [tierId, updatedOrganisation]);

  const handleUpdateOrganisation = (orgId, action) => {
    updateOrganisation({
      variables: {
        orgId,
        organisationDetails: {
          tier: {
            tierId,
            action,
          },
        },
      },
    });
  };

  if (loading) return 'Loading.... Please wait';
  if (error) return 'Error fetching tier';

  return (
    <>
      <LineItem text={tierCombined?.name} title="Tier name" />
      <LineItem text={tierCombined?.description} title="Tier description" />

      <Organisations
        action="REMOVE"
        dataEl="CURRENT-ORGANISATIONS"
        organisations={tierCombined.organisations}
        tier={tierCombined}
        title="Organisations in this Tier"
        updateOrganisation={handleUpdateOrganisation}
      />

      <Organisations
        action="ADD"
        dataEl="AVAILABLE-ORGANISATIONS"
        organisations={tierCombined.availableOrganisations}
        tier={tierCombined}
        title="Organisations available to this Tiers"
        updateOrganisation={handleUpdateOrganisation}
      />
    </>
  );
}
