import { gql } from '@apollo/client';

import { QuickEditThemeDetails } from '../../../fragments';

export const GET_THEME_QUERY = gql`
  query ThemeCurrentState($quickEditThemeId: ID!) {
    seenit {
      quickEditTheme(id: $quickEditThemeId) {
        ...QuickEditThemeDetails
      }
    }
  }

  ${QuickEditThemeDetails}
`;

export const CREATE_QUICK_EDIT_THEME_MUTATION = gql`
  mutation CreateQuickEditThemeMutation($data: CreateQuickEditThemeInput!) {
    seenit {
      createQuickEditTheme(data: $data) {
        ...QuickEditThemeDetails
      }
    }
  }

  ${QuickEditThemeDetails}
`;

export const UPDATE_QUICK_EDIT_THEME_MUTATION = gql`
  mutation UpdateQuickEditThemeMutation(
    $quickEditThemeId: ID!
    $data: UpdateQuickEditThemeInput!
  ) {
    seenit {
      updateQuickEditTheme(id: $quickEditThemeId, data: $data) {
        ...QuickEditThemeDetails
      }
    }
  }

  ${QuickEditThemeDetails}
`;
