import { configure } from 'axios-hooks';
import LRU from 'lru-cache';
import Axios from 'axios';

export default ({ baseURL, spaces, accesstoken, cacheMax }) => {
  const axios = Axios.create({
    baseURL: `${baseURL}/spaces/${spaces}/`,
    headers: {
      Authorization: `Bearer ${accesstoken}`,
    },
  });

  const cache = new LRU({ max: parseInt(cacheMax, 10) });

  configure({ axios, cache });
};
