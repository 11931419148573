import { gql } from '@apollo/client';

export const queries = {
  getEditorMedia: gql`
    query LowerThirdsConfigGetEditorMedia($filter: EditorMediaFilterInput) {
      seenit {
        editorMedia(filter: $filter) {
          editorMediaId
          title
        }
      }
    }
  `,

  getEditorOrgAssets: gql`
    query EditorOrgAssets($filter: EditorAdminGenericFilterInput) {
      seenit {
        editorOrgAssets(filter: $filter) {
          editorOrgAssetId
          name
        }
      }
    }
  `,

  getEditorLowerThirds: gql`
    query LowerThirdConfigBriefCurrentStateGetLowerThirds(
      $filter: EditorAdminGenericFilterInput
    ) {
      seenit {
        lowerThirds(filter: $filter) {
          lowerThirdId
          name
        }
      }
    }
  `,

  getLowerThird: gql`
    query LowerThird($lowerThirdId: ID!) {
      seenit {
        lowerThird(lowerThirdId: $lowerThirdId) {
          background {
            colorByReference {
              editorOrgAssetId
              name
              value
            }
            colorByText
            duration
            editorMedia {
              editorMediaId
            }
            fadeIn
            fadeInRequired
            fadeOut
            fadeOutRequired
            height
            minWidth
            positionX
            positionY
            widthCanGrow
          }
          name
          primaryText {
            colorByReference {
              value
              name
              editorOrgAssetId
            }
            colorByText
            font {
              editorMediaId
            }
            fontSize
            positionX
            positionY
            positonYWhenNoSecondaryText
          }
          secondaryText {
            colorByReference {
              value
              name
              editorOrgAssetId
            }
            colorByText
            font {
              editorMediaId
            }
            fontSize
            positionX
            positionY
            positonYWhenNoSecondaryText
          }
          lowerThirdId
          createdAt
          docType

          orgId
          updatedAt
        }
      }
    }
  `,
};

export const mutations = {
  createLowerThird: gql`
    mutation LowerThirdConfigCreateLowerThird($data: CreateLowerThirdInput!) {
      seenit {
        createLowerThird(data: $data) {
          lowerThirdId
        }
      }
    }
  `,

  updateLowerThird: gql`
    mutation LowerThirdConfigUpdateLowerThird(
      $lowerThirdId: ID!
      $data: UpdateLowerThirdInput!
    ) {
      seenit {
        updateLowerThird(lowerThirdId: $lowerThirdId, data: $data) {
          lowerThirdId
        }
      }
    }
  `,

  deleteLowerThird: gql`
    mutation DeleteLowerThird($lowerThirdId: ID!) {
      seenit {
        deleteLowerThird(lowerThirdId: $lowerThirdId) {
          success
        }
      }
    }
  `,
};
