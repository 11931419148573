import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { IconButton } from '@team-seenit/atoms';
import { FormControl } from '@team-seenit/molecules';

import * as Styled from '../MediaUploadOrganism.styled';
import { mutations } from '../MediaUploadOrganism.gql';

export const MediaTitle = ({ dataEl, mediumId, title }): JSX.Element => {
  const initialValue = {
    title: { value: title || 'Please enter title', valid: true },
  };
  const [editTitle, setEditTitle] = useState<boolean>();
  const [formData, setFormData] = useState(initialValue);

  const [updateMedium, { data, loading }] = useMutation(mutations.updateMedia);

  const onKeyPressed = event => {
    if (['Enter', 'Tab'].includes(event.key)) {
      const { title: { value, valid } = {} } = formData;

      if (!valid || loading) return;
      if (valid) {
        updateMedium({
          variables: {
            editorMediaId: mediumId,
            data: { title: value },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data) setEditTitle(false);
  }, [data]);

  const getValue = id => {
    return formData[id];
  };

  const handleChange = (value, others) => {
    const { id, ...restData } = others;
    setFormData({
      ...formData,
      [id]: { value, ...restData },
    });
  };

  return (
    <>
      {!editTitle && (
        <Styled.TitleWrapper dataEl={dataEl}>
          <IconButton
            icon="edit"
            onClick={() => setEditTitle(true)}
            variant="transparentAccent"
          />
          {getValue('title').value}
        </Styled.TitleWrapper>
      )}
      {editTitle && (
        <FormControl
          dataEl="EditMediaTitle-control"
          inputProps={{
            dataEl: 'EditMediaTitle',
            id: 'title',
            onChange: handleChange,
            onKeyDown: onKeyPressed,
            value: getValue('title').value,
            valid: getValue('title').valid,
            placeholder: 'Image title',
          }}
          type="text"
          validateOn="change"
          validators={[
            {
              errorMessage: 'Please add more than two characters',
              validator: 'minLength',
              min: 3,
            },
          ]}
        />
      )}
    </>
  );
};

MediaTitle.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Medium id. */
  mediumId: PropTypes.string.isRequired,
  /** Title. */
  title: PropTypes.string.isRequired,
};

MediaTitle.defaultProps = {
  dataEl: 'MediaTitle',
};
