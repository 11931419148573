import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@team-seenit/atoms';
import * as Styled from './Organisations.styled';

export const Organisations = ({
  dataEl,
  organisations,
  title,
  action,
  updateOrganisation,
  tier,
}) => {
  const [processingOrgIds, setProcessingOrgIds] = useState([]);

  const updatingOrg = async (orgId, actionCall) => {
    setProcessingOrgIds([...processingOrgIds, orgId]);
    updateOrganisation(orgId, actionCall);
  };

  useEffect(() => {
    setProcessingOrgIds([]);
  }, [tier]);

  return (
    <Styled.OrganisationsSection dataEl={dataEl}>
      <Typography lines={1} variant="subheader">
        {title}
      </Typography>

      <Styled.TableWrapper key={`organisations-${action}`}>
        <table>
          <tr>
            <th>S/N</th>
            <th>ID</th>
            <th>Name</th>
            <th>Nick name</th>
            <th>Action</th>
          </tr>
          {organisations &&
            organisations.map((organisation, index) => (
              <tr key={organisation.orgId}>
                <td>{index + 1}</td>
                <td>{organisation.orgId}</td>
                <td>{organisation.name}</td>
                <td>{organisation.nickname}</td>
                <td>
                  <Button
                    backgroundColor={action === 'REMOVE' ? 'red' : 'accent'}
                    dataEl="Tier-Organisation-Update"
                    large
                    loading={processingOrgIds.includes(organisation.orgId)}
                    onClick={() => {
                      updatingOrg(organisation.orgId, action);
                    }}
                    rounded
                  >
                    {action}
                  </Button>
                </td>
              </tr>
            ))}
        </table>
      </Styled.TableWrapper>
    </Styled.OrganisationsSection>
  );
};

Organisations.propTypes = {
  /** Data el for the component */
  dataEl: PropTypes.string.isRequired,
  /** Organisations list component */
  organisations: PropTypes.shape().isRequired,
  /** Title of the component */
  title: PropTypes.string.isRequired,
  /** Action to perform component */
  action: PropTypes.string.isRequired,
  /** Function to perform in the component */
  updateOrganisation: PropTypes.func.isRequired,
  /** Tier details component */
  tier: PropTypes.shape().isRequired,
};
