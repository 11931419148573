import React, { useState, useEffect } from 'react';
import { useReactiveVar, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Button, Pill, Image, HR } from '@team-seenit/atoms';
import { StatsCard, VideoPlayer } from '@team-seenit/molecules';

import { organisationStatsByOrgIdVar } from '../variables';
import { MediaTitle } from './MediaTitle';

import * as Styled from './MediaCurrentState.styled';

import {
  GET_ORGANISATIONS_STATS_QUERY,
  DELETE_QUICK_EDIT_MEDIUM_MUTATION,
  destructureDataForOrgStats,
} from './MediaCurrentState.gql';

export const MediaCurrentState = ({
  dataEl,
  orgId,
  currentTab,
}): JSX.Element => {
  const [quickEditMediumId, setQuickEditMediumId] = useState();

  const [
    fetchOrgStats,
    { data: getOrgStatsData, error, refetch },
  ] = useLazyQuery(GET_ORGANISATIONS_STATS_QUERY);

  const [deleteQuickEditMedium, { data: deletedMedium }] = useMutation(
    DELETE_QUICK_EDIT_MEDIUM_MUTATION
  );

  const orgRefresh = useReactiveVar(organisationStatsByOrgIdVar);

  useEffect(() => {
    organisationStatsByOrgIdVar(getOrgStatsData);
  }, [getOrgStatsData]);

  useEffect(() => {
    if (orgId && !getOrgStatsData) {
      fetchOrgStats({ variables: { orgId } });
    }
    if (orgId && getOrgStatsData) {
      refetch({ orgId });
    }
  }, [orgId, deletedMedium, orgRefresh, currentTab]);

  useEffect(() => {
    if (quickEditMediumId) {
      deleteQuickEditMedium({ variables: { quickEditMediumId } });

      setQuickEditMediumId(undefined);
    }
  }, [quickEditMediumId]);

  if (error) return <h1>GQL Error</h1>;

  const {
    icon,
    title,
    stats,
    color,
    items,
    name,
    orgFullName,
    avatar,
  } = destructureDataForOrgStats(getOrgStatsData, currentTab);

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Styled.TierWrapper>
        <Pill color="accent" readOnly text={`Current Tier: ${name}`} />
      </Styled.TierWrapper>

      <StatsCard color={color} icon={icon} stats={stats} title={title} />
      {items.map(item => {
        return (
          <Styled.Empty key={item.quickEditMediaId}>
            <h3>{item.quickEditMediaId}</h3>
            <MediaTitle mediumId={item.quickEditMediaId} title={item.title} />
            <Styled.MediaWrapper
              key={item.quickEditMediaId}
              id={item.quickEditMediaId}
            >
              <Button
                icon="cut"
                onClick={() => setQuickEditMediumId(item.quickEditMediaId)}
                variant="outlineDanger"
              >
                Delete
              </Button>
              {item.mediaFormat === 'video' && (
                <Styled.VideoPlayer isAudio={item.isAudio}>
                  {!item.isAudio && (
                    <VideoPlayer
                      borderRadius="8px"
                      captions={{}}
                      childrenVisible
                      dataEl={dataEl}
                      description={item.title}
                      onChangeSubtitles={() => {}}
                      orgAvatar={avatar}
                      orgName={orgFullName}
                      publicId={item.uploadUrl}
                      subtitles={[{}]}
                      subtitlesVisible={false}
                      thumbnailUrl={item.thumbnailUrl}
                      title={item.title}
                      videoControlsVisible
                    />
                  )}
                  {item.isAudio && (
                    <audio controls>
                      <track kind="captions" />
                      <source src={item.uploadUrl} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                </Styled.VideoPlayer>
              )}
              {item.mediaFormat === 'image' && (
                <Styled.ImageDisplay>
                  <Image
                    borderRadius="8px"
                    dataEl="Image"
                    height="136px"
                    objectFit="contain"
                    publicId={item.publicId}
                    src={item.thumbnailUrl}
                    width="208px"
                  />
                </Styled.ImageDisplay>
              )}
            </Styled.MediaWrapper>
            <HR color="accent" />
          </Styled.Empty>
        );
      })}
    </Styled.Wrapper>
  );
};

MediaCurrentState.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Current org id. */
  orgId: PropTypes.string.isRequired,
  /** Current tab */
  currentTab: PropTypes.string.isRequired,
};

MediaCurrentState.defaultProps = {
  dataEl: 'MediaCurrentState',
};
