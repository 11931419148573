import React from 'react';
import { Button, ActionCard, HR } from '@team-seenit/atoms';
import * as Styled from './CurrentStateActionCard.styled';

export const CurrentStateActionCard = ({
  id,
  onDeleteClick,
  disabled,
  name,
  onCardClick = () => {},
}: {
  id: string;
  onDeleteClick: () => void;
  disabled: boolean;
  name: string;
  onCardClick?: () => void;
}) => {
  return (
    <Styled.Wrapper>
      <Styled.MediaWrapper id={id}>
        <Button icon="cut" onClick={onDeleteClick} variant="outlineDanger">
          Delete
        </Button>
        <Styled.ImageDisplay>
          <ActionCard
            cardStyle="ghost"
            cardStyleHover="ghostHover"
            disabled={disabled}
            width="100%"
            icon="edit"
            iconSize="massive"
            mainText={name}
            mainTextVariant="subheader"
            onClick={onCardClick}
            subText="Click to edit"
            subTextVariant="bodyBold"
          />
        </Styled.ImageDisplay>
      </Styled.MediaWrapper>
      <Styled.HRWrapper>
        <HR color="accent" />
      </Styled.HRWrapper>
    </Styled.Wrapper>
  );
};
