import React from 'react';
import { useReactiveVar } from '@apollo/client';

import PropTypes from 'prop-types';

import { Typography } from '@team-seenit/atoms';
import { FormControl } from '@team-seenit/molecules';

import {
  quickEditOrgStatsDataVar,
  quickEditThemeFormDataVar,
} from '../variables';

import * as Styled from './ThemesBrief.styled';

export const ThemeSectionSelectionsBrief = ({ dataEl, section }) => {
  const quickEditThemeFormData = useReactiveVar(quickEditThemeFormDataVar);
  const quickEditOrgStatsData = useReactiveVar(quickEditOrgStatsDataVar);

  const { seenit: { organisation: { quickEditsConfig } = {} } = {} } =
    quickEditOrgStatsData || {};

  const handleChange = subSection => (value, others) => {
    const { id } = others;

    let currentValue = quickEditThemeFormData[section][subSection].value;

    if (value === true) currentValue.push(id);
    if (!value) currentValue = currentValue.filter(item => item !== id);

    quickEditThemeFormDataVar({
      ...quickEditThemeFormData,
      [section]: {
        ...quickEditThemeFormData[section],
        [subSection]: {
          ...quickEditThemeFormData[section][subSection],
          value: currentValue,
        },
      },
    });
  };

  const subSections = {
    openingMedia: 'Opening media selections',
    closingMedia: 'Closing media selections',
    audioTracks: 'Audio tracksselections',
  };

  return (
    <Styled.Wrapper>
      {Object.keys(subSections).map(subSection => (
        <Styled.Wrapper key={`SUBECTION-${subSection}`}>
          <Typography variant="bodyBold">{subSections[subSection]}</Typography>

          {quickEditsConfig[subSection].map((medium, index) => (
            <FormControl
              key={medium.quickEditMediaId}
              dataEl={`${dataEl}-control`}
              hint={medium.quickEditMediaId}
              inputProps={{
                dataEl: `SELECTION-Opening-${index}`,
                id: medium.quickEditMediaId,
                value: quickEditThemeFormData[section][
                  subSection
                ].value.includes(medium.quickEditMediaId),
                onChange: handleChange(subSection),
                readOnly: false,
              }}
              question={medium.title || 'No Name'}
              type="switch"
              validateOn="change"
            />
          ))}
        </Styled.Wrapper>
      ))}
    </Styled.Wrapper>
  );
};

ThemeSectionSelectionsBrief.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Section. */
  section: PropTypes.string.isRequired,
};

ThemeSectionSelectionsBrief.defaultProps = {
  dataEl: 'ThemeSectionSelectionsBrief',
};
