import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  orientation: yup.string().required('Orientation is required'),
  logo: yup.object({
    editorMediaId: yup.string().nullable(),
    height: yup
      .number()
      .nullable()
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positionX: yup
      .number()
      .nullable()
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positionY: yup
      .number()
      .nullable()
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    width: yup
      .number()
      .nullable()
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
  }),
  openingVideos: yup.object(),
  closingVideos: yup.object(),
  musicTracks: yup.object(),
  lowerThirds: yup.object(),
});
