import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';

import { AUTH } from '@team-seenit/constants';
import { Button, Tabs, Pill } from '@team-seenit/atoms';
import { FormControl } from '@team-seenit/molecules';
import { useAuth } from '@team-seenit/contexts';

import {
  quickEditThemeIdVar,
  quickEditThemesReloadVar,
  quickEditThemeFormDataVar,
  quickEditThemeFormErrorVar,
} from '../variables';

import {
  prepopulateBrief,
  prepopulateBriefAfterUpdate,
  formDataBySection,
  defaultFormProps,
} from '../helpers';

import { ThemeSectionBrief } from './ThemeSectionBrief';
import { Error } from '../Error';

import * as Styled from './ThemesBrief.styled';

import {
  CREATE_QUICK_EDIT_THEME_MUTATION,
  UPDATE_QUICK_EDIT_THEME_MUTATION,
  GET_THEME_QUERY,
} from './ThemesBrief.gql';

const { USER_ID_KEY } = AUTH;

const tabs = [
  { dataEl: 'Opening', id: 'background', value: 'background' },
  { dataEl: 'Closing', id: 'textLine1', value: 'textLine1' },
  { dataEl: 'Audios', id: 'textLine2', value: 'textLine2' },
  { dataEl: 'Watermarks', id: 'watermark', value: 'watermark' },
  { dataEl: 'Selections', id: 'selections', value: 'selections' },
];

export const ThemesBrief = ({ dataEl, orgId }): JSX.Element => {
  const { user: { [USER_ID_KEY]: userId } = {} } = useAuth();
  const [briefCurrentTab, setBriefCurrentTab] = useState('background');

  const [fetchQuickEditTheme, { data: fetchedTheme }] = useLazyQuery(
    GET_THEME_QUERY
  );
  const [
    createQuickEditTheme,
    { data: createdTheme, error: creatingThemeError, loading: creatingTheme },
  ] = useMutation(CREATE_QUICK_EDIT_THEME_MUTATION);
  const [
    updateQuickEditTheme,
    { data: updatedTheme, error: updatingThemeError, loading: updatingTheme },
  ] = useMutation(UPDATE_QUICK_EDIT_THEME_MUTATION);

  const quickEditThemeFormData = useReactiveVar(quickEditThemeFormDataVar);
  const quickEditThemeId = useReactiveVar(quickEditThemeIdVar);

  const resetForm = () => {
    quickEditThemeFormErrorVar(undefined);
    quickEditThemeIdVar(undefined);
    quickEditThemeFormDataVar(defaultFormProps);
  };

  const getValue = formKey => {
    const { [formKey]: value } = quickEditThemeFormData || {};
    return value;
  };

  const buttonTitle = () => {
    return quickEditThemeId ? 'Update theme' : 'Create theme';
  };

  useEffect(() => {
    if (quickEditThemeId) {
      fetchQuickEditTheme({ variables: { quickEditThemeId } });
    }
  }, [quickEditThemeId]);

  useEffect(() => {
    if (fetchedTheme && quickEditThemeId) {
      const response = prepopulateBrief(fetchedTheme);

      quickEditThemeFormDataVar(response);
    }

    if (!quickEditThemeId) {
      resetForm();
    }
  }, [quickEditThemeId, fetchedTheme]);

  const handleChange = (value, others) => {
    const { id, valid } = others;

    quickEditThemeFormDataVar({
      ...quickEditThemeFormData,
      [id]: {
        ...quickEditThemeFormData[id],
        value,
        valid,
      },
    });
  };

  useEffect(() => {
    if (createdTheme && !creatingThemeError) {
      const {
        seenit: {
          createQuickEditTheme: {
            quickEditThemeId: createdQuickEditThemeId,
          } = {},
        } = {},
      } = createdTheme;

      quickEditThemesReloadVar('Created');
      quickEditThemeIdVar(createdQuickEditThemeId);
    }
  }, [createdTheme]);

  useEffect(() => {
    if (quickEditThemeId && updatedTheme && !updatingThemeError) {
      quickEditThemesReloadVar('Updated');
      const response = prepopulateBriefAfterUpdate(updatedTheme);
      quickEditThemeFormDataVar(response);
    }
  }, [quickEditThemeId, updatedTheme]);

  useEffect(() => {
    if (creatingThemeError) quickEditThemeFormErrorVar(creatingThemeError);
    if (updatingThemeError) quickEditThemeFormErrorVar(updatingThemeError);
  }, [creatingThemeError, updatingThemeError]);

  const mutationData = () => ({
    orgId,
    createdBy: userId,
    name: getValue('name').value,
    ...formDataBySection('background', quickEditThemeFormData),
    ...formDataBySection('textLine1', quickEditThemeFormData),
    ...formDataBySection('textLine2', quickEditThemeFormData),
    ...formDataBySection('watermark', quickEditThemeFormData),
    openingMedia: getValue('selections').openingMedia.value,
    closingMedia: getValue('selections').closingMedia.value,
    audioTracks: getValue('selections').audioTracks.value,
  });

  const buttonClicked = () => {
    quickEditThemeFormErrorVar(undefined);

    if (quickEditThemeId) {
      updateQuickEditTheme({
        variables: {
          quickEditThemeId,
          data: mutationData(),
        },
      });
    } else {
      createQuickEditTheme({
        variables: {
          data: mutationData(),
        },
      });
    }
  };

  const singleKeys = ['name'];
  const isFormValid = () => {
    let subSectionValid = true;
    let keysOfInterest = [];
    if (briefCurrentTab) {
      keysOfInterest = Object.keys(quickEditThemeFormData[briefCurrentTab]);
      subSectionValid = keysOfInterest.every(
        key => quickEditThemeFormData[briefCurrentTab][key].valid
      );
    }

    return (
      singleKeys.every(key => quickEditThemeFormData[key].valid) &&
      subSectionValid
    );
  };

  const formChanged = () => {
    let subSectionChanged = false;
    let keysOfInterest = [];
    if (briefCurrentTab) {
      keysOfInterest = Object.keys(quickEditThemeFormData[briefCurrentTab]);
      subSectionChanged = keysOfInterest.some(
        key =>
          quickEditThemeFormData[briefCurrentTab][key].value !==
          quickEditThemeFormData[briefCurrentTab][key].initialValue
      );
    }
    const singleKeyChanged = singleKeys.some(
      key =>
        quickEditThemeFormData[key].value !==
        quickEditThemeFormData[key].initialValue
    );

    return singleKeyChanged || subSectionChanged;
  };

  return (
    <Styled.Wrapper>
      <Styled.PillWrapper>
        <Pill
          color="red"
          readOnly
          text={quickEditThemeId ? `Updating theme` : `Creating theme`}
        />
      </Styled.PillWrapper>

      <Tabs
        onChange={setBriefCurrentTab}
        selectedTab={briefCurrentTab}
        tabs={tabs}
        tabStyle="switch"
      />
      <FormControl
        dataEl={`${dataEl}-control`}
        inputProps={{
          dataEl,
          id: 'name',
          onChange: handleChange,
          value: getValue('name').value,
          valid: getValue('name').valid,
          placeholder: 'Theme name goes here...',
        }}
        question="Theme name"
        type="text"
        validateOn="change"
        validators={[
          {
            errorMessage: 'Please add more than two characters',
            validator: 'minLength',
            min: 3,
          },
        ]}
      />

      {briefCurrentTab && <ThemeSectionBrief section={briefCurrentTab} />}

      <Error />

      <Button
        animation="pulse"
        dataEl="OpeningMediaSubmitButton"
        disabled={!isFormValid() || !formChanged()}
        id="openingMediaSubmit"
        large
        loading={creatingTheme || updatingTheme}
        onClick={buttonClicked}
        type="submit"
        width="100%"
      >
        {buttonTitle()}
      </Button>
    </Styled.Wrapper>
  );
};

ThemesBrief.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Org id. */
  orgId: PropTypes.string.isRequired,
};

ThemesBrief.defaultProps = {
  dataEl: 'ThemesBrief',
};
