import React from 'react';
import PropTypes from 'prop-types';
import addDays from 'date-fns/addDays';

import { Typography, HR } from '@team-seenit/atoms';
import { FormControl } from '@team-seenit/molecules';

import * as Styled from './TierFeature.styled';

export function TierFeature({
  dataEl,
  item,
  handleFormChanges,
  getValue,
}: TiersOrgProps): JSX.Element {
  const featureEnabled = getValue(`${item.registeredFeatureId}-enable`)?.value;

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Styled.LineItem>
        <Typography dataEl="CreateTier" variant="bodyBold">
          Enable feature:
        </Typography>

        <FormControl
          dataEl={`ENABLE-${item.dataEl}`}
          hint={item.name}
          hintStyle="inline"
          inputProps={{
            dataEl,
            id: `${item.registeredFeatureId}-enable`,
            onChange: handleFormChanges,
            value: featureEnabled,
            valid: getValue(`${item.registeredFeatureId}-enable`)?.valid,
            placeholder: 'Image fadeOutRequired',
            prefix: 'I love cats because',
            readOnly: item.enableReadonly || false,
          }}
          question={item.enableReadonlyMessage || 'Activate this feature'}
          type="switch"
          validateOn="blur"
          validators={[
            {
              errorMessage: 'Required',
              validator: 'required',
            },
          ]}
        />
      </Styled.LineItem>

      {featureEnabled && (
        <>
          <Styled.LineItem>
            <Typography dataEl="CreateTier" variant="bodyBold">
              Name:
            </Typography>

            <Typography dataEl="CreateTier" variant="body">
              {item.name}
            </Typography>
          </Styled.LineItem>

          <Styled.LineItem>
            <Typography dataEl="CreateTier" variant="bodyBold">
              Description:
            </Typography>

            <Typography dataEl="CreateTier" variant="body">
              {item.description}
            </Typography>
          </Styled.LineItem>

          <Styled.LineItem>
            <Typography dataEl="CreateTier" variant="bodyBold">
              Value:
            </Typography>

            {item.valueIndicator === 'boolean' && (
              <FormControl
                dataEl={item.dataEl}
                hint="This activate or deactivate use of this feature"
                hintStyle="inline"
                inputProps={{
                  dataEl,
                  id: `${item.registeredFeatureId}-value`,
                  onChange: handleFormChanges,
                  value: getValue(`${item.registeredFeatureId}-value`)?.value,
                  valid: getValue(`${item.registeredFeatureId}-value`)?.valid,
                  placeholder: 'Toggle this feature',
                  // prefix: 'I love cats because',
                  readOnly: false,
                }}
                question="Toggle the value on / off?"
                type="switch"
                validateOn="change"
                // validators={[
                //   {
                //     errorMessage: 'Required',
                //     validator: 'required',
                //   },
                // ]}
              />
            )}

            {item.valueIndicator === 'text' && (
              <FormControl
                dataEl={item.dataEl}
                hint="This activate or deactivate use of this feature"
                hintStyle="inline"
                inputProps={{
                  dataEl,
                  id: `${item.registeredFeatureId}-value`,
                  onChange: handleFormChanges,
                  value: getValue(`${item.registeredFeatureId}-value`)?.value,
                  valid: getValue(`${item.registeredFeatureId}-value`)?.valid,
                  placeholder: 'Text value for this feature',
                  // prefix: 'I love cats because',
                  readOnly: false,
                }}
                question="Text for this feature"
                type="text"
                validateOn="change"
                validators={[
                  {
                    errorMessage: 'Required',
                    validator: 'required',
                  },
                  {
                    errorMessage: 'Required',
                    validator: 'minLength',
                    min: 1,
                  },
                ]}
              />
            )}

            {item.valueIndicator === 'number' && (
              <FormControl
                dataEl={item.dataEl}
                hint="Sets the number for this feature"
                hintStyle="inline"
                inputProps={{
                  dataEl,
                  id: `${item.registeredFeatureId}-value`,
                  onChange: handleFormChanges,
                  value: getValue(`${item.registeredFeatureId}-value`)?.value,
                  valid: getValue(`${item.registeredFeatureId}-value`)?.valid,
                  placeholder: 'Number for this feature',
                  // prefix: 'I love cats because',
                  readOnly: false,
                }}
                question="Number for this feature"
                type="text"
                validateOn="change"
                validators={[
                  {
                    errorMessage: 'This is required',
                    validator: 'required',
                  },
                  {
                    errorMessage: 'Please use only numbers',
                    validator: 'pattern',
                    regex: /^[0-9]*$/,
                  },
                ]}
              />
            )}
          </Styled.LineItem>
        </>
      )}
      {featureEnabled && item.type === 'addon' && (
        <>
          <FormControl
            dataEl="AddonStartDate"
            hint="Addon start date"
            inputProps={{
              dataEl: 'StoryDeadlineInput',
              id: `${item.registeredFeatureId}-startdate`,
              onFocus: console.log('===='),
              onChange: handleFormChanges,
              value: getValue(`${item.registeredFeatureId}-startdate`)?.value,
              valid: getValue(`${item.registeredFeatureId}-startdate`)?.valid,
              readOnly: item.startDateReadonly,
            }}
            question={item.startDateReadonlyMessage || 'Start date'}
            type="datepicker"
            validateOn="change"
          />
          <FormControl
            dataEl="AddonEndDate"
            hint="Addon end date"
            inputProps={{
              dataEl: 'StoryDeadlineInput',
              id: `${item.registeredFeatureId}-enddate`,
              onChange: handleFormChanges,
              onFocus: console.log('===='),
              minDate: addDays(new Date(), 0),
              value: getValue(`${item.registeredFeatureId}-enddate`)?.value,
              valid: getValue(`${item.registeredFeatureId}-enddate`)?.valid,
            }}
            question="End date"
            type="datepicker"
            validateOn="change"
          />
        </>
      )}
      <HR color="green" />
    </Styled.Wrapper>
  );
}

TierFeature.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
};

TierFeature.defaultProps = {
  dataEl: 'TierFeature',
};
