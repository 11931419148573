/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Typography, Switch } from '@team-seenit/atoms';
import { Controller, useFormContext } from 'react-hook-form';
import { editorBrandsOrgDataVar } from '../variables';
import * as Styled from '../Brands.styled';

export const BrandsFormSectionSelections = ({
  dataEl = 'BrandSectionSelectionsBrief',
}: {
  dataEl: string;
}) => {
  const editorBrandsOrgData = useReactiveVar(editorBrandsOrgDataVar);

  const { control, getValues, setValue } = useFormContext();

  const subSections = {
    openingVideos: 'Opening media selections',
    closingVideos: 'Closing media selections',
    musicTracks: 'Audio tracks selections',
  };

  return (
    <Styled.Wrapper>
      {Object.keys(subSections).map(subSection => (
        <>
          {editorBrandsOrgData?.[subSection]?.length ? (
            <Styled.Wrapper key={`SUBECTION-${subSection}`}>
              <Typography variant="bodyBold">
                {subSections[subSection]}
              </Typography>

              {editorBrandsOrgData?.[subSection]?.map(medium => (
                <Controller
                  key={`${subSection}.${medium.editorMediaId}`}
                  name={`${subSection}.${medium.editorMediaId}`}
                  control={control}
                  render={({ field }) => (
                    <Styled.SwitchWrapper>
                      <Switch
                        {...field}
                        checked={
                          getValues(`${subSection}.${medium.editorMediaId}`) ??
                          false
                        }
                        dataEl={dataEl}
                        id={medium.editorMediaId}
                      />
                      <Typography
                        lines={0}
                        variant="label"
                        css={{ marginBottom: '4px' }}
                      >
                        {medium.title || 'No Name'}
                      </Typography>
                    </Styled.SwitchWrapper>
                  )}
                />
              ))}
            </Styled.Wrapper>
          ) : null}
        </>
      ))}
      {editorBrandsOrgData?.lowerThirds?.length ? (
        <Styled.Wrapper>
          <Typography variant="bodyBold">lowerThirds</Typography>

          {editorBrandsOrgData?.lowerThirds?.map(lowerThird => (
            <Controller
              key={`lowerThirds.${lowerThird.lowerThirdId}`}
              name={`lowerThirds.${lowerThird.lowerThirdId}`}
              control={control}
              render={({ field }) => (
                <Styled.SwitchWrapper>
                  <Switch
                    {...field}
                    checked={
                      getValues(`lowerThirds.${lowerThird.lowerThirdId}`) ??
                      false
                    }
                    onChange={val => {
                      if (val) {
                        Object.keys(getValues('lowerThirds')).forEach(lt => {
                          if (lt === lowerThird.lowerThirdId)
                            setValue(`lowerThirds.${lt}`, true);
                          else setValue(`lowerThirds.${lt}`, false);
                        });
                      } else field.onChange(val);
                    }}
                    dataEl={dataEl}
                    id={lowerThird.lowerThirdId}
                  />
                  <Typography
                    lines={0}
                    variant="label"
                    css={{ marginBottom: '4px' }}
                  >
                    {lowerThird.name || 'No Name'}
                  </Typography>
                </Styled.SwitchWrapper>
              )}
            />
          ))}
        </Styled.Wrapper>
      ) : null}
    </Styled.Wrapper>
  );
};
