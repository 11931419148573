import { gql } from '@apollo/client';
import { RegisteredFeatureDetails } from '../../fragments';

export const CREATE_REGISTERED_FEATURES_MUTATION = gql`
  mutation AdminCreateRegisteredFeature(
    $createRegisteredFeature: CreateRegisteredFeatureInput!
  ) {
    seenit {
      createRegisteredFeature(
        createRegisteredFeature: $createRegisteredFeature
      ) {
        ...RegisteredFeatureDetails
      }
    }
  }

  ${RegisteredFeatureDetails}
`;

export const UPDATE_REGISTERED_FEATURES_MUTATION = gql`
  mutation AdminUpdateRegisteredFeature(
    $registeredFeatureId: ID!
    $updateRegisteredFeature: UpdateRegisteredFeatureInput!
  ) {
    seenit {
      updateRegisteredFeature(
        registeredFeatureId: $registeredFeatureId
        updateRegisteredFeature: $updateRegisteredFeature
      ) {
        ...RegisteredFeatureDetails
      }
    }
  }

  ${RegisteredFeatureDetails}
`;

export const DELETE_REGISTERED_FEATURES_MUTATION = gql`
  mutation AdminDeleteRegisteredFeature($registeredFeatureId: ID!) {
    seenit {
      deleteRegisteredFeature(registeredFeatureId: $registeredFeatureId) {
        success
      }
    }
  }
`;

export const GET_REGISTERED_FEATURE_BY_ID_QUERY = gql`
  query AdminGetAllFeatures($registeredFeatureId: ID!) {
    seenit {
      admin {
        registeredFeature(registeredFeatureId: $registeredFeatureId) {
          ...RegisteredFeatureDetails
        }
      }
    }
  }

  ${RegisteredFeatureDetails}
`;
