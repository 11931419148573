import styled from 'styled-components';

export const TableWrapper = styled.div(({ theme: { palette: { grey2 } } }) => ({
  table: {
    width: '100%',
  },

  'td, th': {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '1px',
  },

  th: {
    backgroundColor: grey2,
  },

  'tr:nth-child(even)': {
    backgroundColor: '#dddddd',
  },
}));

// export const SectionWrapper = styled.div(() => ({
//   marginLeft: '20px',
// }));

// export const ContentWrapper = styled.div(() => ({
//   overflowY: scroll,
// }));

export const OrganisationsSection = styled.div(() => ({
  marginTop: '20px',
}));
