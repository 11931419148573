import React, { useState } from 'react';

import styled from 'styled-components';
import { ErrorBoundary } from '@sentry/react';
import { useAnalytics } from '@team-seenit/contexts';
import { Typography } from '@team-seenit/atoms';
import { StudioTopBar, StaticPages } from '@team-seenit/organisms';
import { StudioTemplate } from '@team-seenit/templates';
import { CONTENTFUL } from '@team-seenit/constants';
import { Toggles } from '../organisms/Toggles/Toggles';
import { CreateToggle } from '../organisms/CreateToggle/CreateToggle';
import { MenuDesktopHelper } from './components/MenuDesktopHelper';

const {
  KEYS: { PROJECTS_MAIN_ERROR_GRAPHQL },
} = CONTENTFUL;

const Main = styled.main(
  ({ theme: { breakpoints: { min: { desktop = undefined } = {} } = {} } }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 16px',
    width: '100%',
    'z-index': 0,
    [`@media (min-width: ${desktop})`]: {
      padding: '32px',
    },
  })
);

export const TogglesPage = () => {
  const { page } = useAnalytics();
  const [creatingToggle, setCreatingToggle] = useState();

  page('ADMIN', 'Admin Toggles');

  return (
    <StudioTemplate
      dataEl="TogglesPage"
      mainComponent={
        <ErrorBoundary
          fallback={({ eventId }) => (
            <StaticPages.ErrorGraphQL
              contentLocationKey={PROJECTS_MAIN_ERROR_GRAPHQL}
              eventId={eventId}
            />
          )}
        >
          <Main>
            <Typography variant="header">Toggles</Typography>

            <Typography variant="header">
              Tooltip appears broken in Pluto, hence menu items are just icons
            </Typography>
            <Typography variant="header">Logout using the avatars</Typography>

            <CreateToggle creatingToggle={setCreatingToggle} />
            {!creatingToggle && <Toggles />}
          </Main>
        </ErrorBoundary>
      }
      menuDesktopComponent={<MenuDesktopHelper />}
      topBarComponent={<StudioTopBar />}
    />
  );
};
