export const prepareMediaForSelect = data => {
  const { seenit: { editorMedia = [] } = {} } = data || {};

  const defaultMedia = {
    value: '-1',
    text: 'Select from the list or leave this to remove media',
  };

  return [
    defaultMedia,
    ...editorMedia.map(({ editorMediaId, title }) => ({
      value: editorMediaId,
      text: title || editorMediaId,
    })),
  ];
};

export const prepareEditorOrgAssetForSelect = data => {
  const { seenit: { editorOrgAssets = [] } = {} } = data || {};

  const defaultAsset = {
    value: '-1',
    text: 'Select from the list or leave this to remove media',
  };

  return [
    defaultAsset,
    ...editorOrgAssets.map(({ editorOrgAssetId, name }) => ({
      value: editorOrgAssetId,
      text: name || editorOrgAssetId,
    })),
  ];
};
