import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  GET_ORGANISATION_QUERY,
  DELETE_TIER_MUTATION,
  GET_TIER_BY_ID_QUERY,
  UPDATE_ORGANISATION_MUTATION,
  destructureDataForOrganisation,
  availableAddons,
} from './Brief.gql';

import { OrganisationBriefPresenter } from './OrganisationBriefPresenter';
import { prepDefaultFeatures } from '../../helpers';

export function EditOrganisation({
  getValue,
  handleFormChanges,
  isformValid,
  setSubmitted,
  submitted,
  userData,
  setUserData,
  registeredFeatures,
  orgId,
  hasOrganisationChanged,
}: OrganisationsOrgProps): JSX.Element {
  const { push } = useHistory();
  const { tierId } = useParams();

  const [getOrganisationById, { data: orgData }] = useLazyQuery(
    GET_ORGANISATION_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const { organisation } = destructureDataForOrganisation(orgData);

  const availableAddonsProps = availableAddons(
    registeredFeatures,
    organisation
  );

  const [getTierById, { data: tierData, error: fetchError }] = useLazyQuery(
    GET_TIER_BY_ID_QUERY
  );

  // const [
  //   updateTier,
  //   { data: updateTierData, error: updateTierError },
  // ] = useMutation(UPDATE_TIER_MUTATION);

  const [
    deleteTier,
    { data: deleteTierData, error: deleteTierError },
  ] = useMutation(DELETE_TIER_MUTATION);

  const [
    updateOrganisation,
    { data: updatedOrganisation, error: updateOrganisationError },
  ] = useMutation(UPDATE_ORGANISATION_MUTATION);

  const { seenit: { admin: { tier = {} } = {} } = {} } = tierData || {};

  useEffect(() => {
    getOrganisationById({
      variables: { orgId },
    });
  }, [orgId]);

  useEffect(() => {
    if (!orgData) return;

    setUserData({
      ...userData,
      ...prepDefaultFeatures({
        features: availableAddonsProps,
        prefix: 'addon-feature-',
        addon: true,
      }),
    });
  }, [organisation, registeredFeatures]);

  useEffect(() => {
    if (tierId)
      getTierById({
        variables: { tierId },
      });
  }, [tierId, updatedOrganisation]);

  useEffect(() => {
    if (updateOrganisationError || deleteTierError) {
      setSubmitted(false);
    }
  }, [updateOrganisationError, deleteTierError]);

  useEffect(() => {
    if (updatedOrganisation) {
      setSubmitted(false);
    }
  }, [updatedOrganisation]);

  useEffect(() => {
    if (deleteTierData && !deleteTierError) {
      setSubmitted(false);
      push(`/Organisations`);
    }
  }, [deleteTierData]);

  // const buildFeatureValue = () => {
  //   return Object.keys(userData)
  //     .map(key => {
  //       if (key.startsWith('feature-')) {
  //         const { featureValueId, value } = userData[key];
  //         const registeredFeatureId = key.replace('feature-', '');
  //         return {
  //           featureValueId,
  //           registeredFeatureId,
  //           value: value.toString(),
  //         };
  //       }
  //     })
  //     .filter(Boolean);
  // };

  const actionPicker = (featureEnabled, addonId) => {
    if (!featureEnabled && addonId) return 'DELETE';
    if (featureEnabled && addonId) return 'UPDATE';
    if (featureEnabled && !addonId) return 'CREATE';
  };

  const buildAddons = () => {
    return Object.keys(userData)
      .map(key => {
        if (key.startsWith('addon-feature-') && key.endsWith('-enable')) {
          const registeredFeatureId = key
            .replace('addon-feature-', '')
            .replace('-enable', '');

          const { value: featureEnabled } = userData[key];
          const { value, featureAddonId } = userData[
            key.replace('-enable', '-value')
          ];

          if (!featureEnabled && !featureAddonId) return;

          const { value: startDate } = userData[
            key.replace('-enable', '-startdate')
          ];
          const { value: endDate } = userData[
            key.replace('-enable', '-enddate')
          ];

          return {
            action: actionPicker(featureEnabled, featureAddonId),
            registeredFeatureId,
            featureAddonId,
            value: value.toString(),
            startDate: Date.parse(startDate).toString(),
            endDate: Date.parse(endDate).toString(),
          };
        }
        return null;
      })
      .filter(Boolean);
  };

  const handleFormSubmission = () => {
    setSubmitted(true);
    updateOrganisation({
      variables: {
        orgId,
        organisationDetails: {
          addons: buildAddons(),
        },
      },
    });
  };

  const handleDeleteClick = () => {
    setSubmitted(true);
    if (tierId)
      deleteTier({
        variables: {
          tierId,
        },
      });
  };

  const handleUpdateOrganisation = action => {
    updateOrganisation({
      variables: {
        orgId,
        organisationDetails: {
          tier: {
            tierId,
            action,
          },
        },
      },
    });
  };

  return (
    <OrganisationBriefPresenter
      availableAddons={availableAddonsProps}
      buttonTitle="Edit tier"
      dataEl="EditTier"
      errors={updateOrganisationError || deleteTierError}
      fetchError={fetchError}
      getValue={getValue}
      handleDeleteClick={handleDeleteClick}
      handleFormChanges={handleFormChanges}
      handleFormSubmission={handleFormSubmission}
      hasOrganisationChanged={hasOrganisationChanged}
      isformValid={isformValid}
      organisation={organisation}
      registeredFeatures={registeredFeatures}
      submitted={submitted}
      tier={tier}
      tierId={tierId}
      title="Editing tier"
      updateOrganisation={handleUpdateOrganisation}
    />
  );
}
