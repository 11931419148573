import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  gap: '10px',
}));

export const TabSideWrapper = styled.div(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const MediaWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
  alignItems: 'center',
  gap: '10px',
  margin: '10px 0',
}));

export const VideoPlayer = styled.div(({ isAudio }) => ({
  height: isAudio ? '100px' : '375px',
  width: '100%',
  marginLeft: '10px',
}));

export const ImageDisplay = styled.div(() => ({
  height: '150px',
  width: '100%',
  marginLeft: '10px',
  backgroundColor: 'grey',
}));

export const TierWrapper = styled.div(() => ({
  marginBottom: '20px',
}));

export const TitleWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const Empty = styled.div(() => ({}));

export const CustomFont = styled.h3(({ fontUrl, fontFamily }) => ({
  margin: '0',
  '@font-face': {
    fontFamily,
    src: `url(${fontUrl}) format('opentype')`,
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  fontFamily: `${fontFamily}, sans-serif`,
}));
